// tslint:disable
// this is an auto generated file. This will be overwritten

export const getCompany = `query GetCompany($id: String!) {
  getCompany(id: $id) {
    id
    email
    call_center
    english_name
    arabic_name
    added_date
    documents {
      label
      description
      uploaded_by
      uploaded_for
      uploaded_date
      url
    }
  }
}
`;
export const getBranches = `query GetBranches($company_id: String!) {
  getBranches(company_id: $company_id) {
    company_info {
      id
      email
      call_center
      english_name
      arabic_name
      added_date
      documents {
        label
        description
        uploaded_by
        uploaded_for
        uploaded_date
        url
      }
    }
    id
    english_name
    arabic_name
    added_date
    status
    city
    phone
    location {
      lat
      lng
    }
    hours {
      enabled
      label
      first_open
      first_close
      second_open
      second_close
      second_shift
      timings
    }
  }
}
`;
export const getCars = `query GetCars($company_id: String!, $branch_id: String) {
  getCars(company_id: $company_id, branch_id: $branch_id) {
    company_info {
      id
      email
      call_center
      english_name
      arabic_name
      added_date
      documents {
        label
        description
        uploaded_by
        uploaded_for
        uploaded_date
        url
      }
    }
    branch_info {
      company_info {
        id
        email
        call_center
        english_name
        arabic_name
        added_date
        documents {
          label
          description
          uploaded_by
          uploaded_for
          uploaded_date
          url
        }
      }
      id
      english_name
      arabic_name
      added_date
      status
      city
      phone
      location {
        lat
        lng
      }
      hours {
        enabled
        label
        first_open
        first_close
        second_open
        second_close
        second_shift
        timings
      }
    }
    plate
    model
    current_km
    daily_km
    extra_km
    status
    city
    driver
    driverPrice
    delivery
    deliveryPrice
    daily
    weekly
    monthly
    category
    fuel
    gearbox
    ac
    seats
    navigation
    insurance
  }
}
`;
export const getCustomers = `query GetCustomers($company_id: String!) {
  getCustomers(company_id: $company_id) {
    id
    company_info {
      id
      email
      call_center
      english_name
      arabic_name
      added_date
      documents {
        label
        description
        uploaded_by
        uploaded_for
        uploaded_date
        url
      }
    }
    email
    phone
    birth_date
    id_expiration
    license_expiration
    nationality
    occupation
    added_date
    status
    documents {
      label
      description
      uploaded_by
      uploaded_for
      uploaded_date
      url
    }
  }
}
`;
export const getReservations = `query GetReservations(
  $company_id: String
  $branch_id: String
  $customer_id: String
  $car_id: String
) {
  getReservations(
    company_id: $company_id
    branch_id: $branch_id
    customer_id: $customer_id
    car_id: $car_id
  ) {
    id
    company_info {
      id
      email
      call_center
      english_name
      arabic_name
      added_date
      documents {
        label
        description
        uploaded_by
        uploaded_for
        uploaded_date
        url
      }
    }
    branch_info {
      company_info {
        id
        email
        call_center
        english_name
        arabic_name
        added_date
        documents {
          label
          description
          uploaded_by
          uploaded_for
          uploaded_date
          url
        }
      }
      id
      english_name
      arabic_name
      added_date
      status
      city
      phone
      location {
        lat
        lng
      }
      hours {
        enabled
        label
        first_open
        first_close
        second_open
        second_close
        second_shift
        timings
      }
    }
    car_info {
      company_info {
        id
        email
        call_center
        english_name
        arabic_name
        added_date
        documents {
          label
          description
          uploaded_by
          uploaded_for
          uploaded_date
          url
        }
      }
      branch_info {
        company_info {
          id
          email
          call_center
          english_name
          arabic_name
          added_date
          documents {
            label
            description
            uploaded_by
            uploaded_for
            uploaded_date
            url
          }
        }
        id
        english_name
        arabic_name
        added_date
        status
        city
        phone
        location {
          lat
          lng
        }
        hours {
          enabled
          label
          first_open
          first_close
          second_open
          second_close
          second_shift
          timings
        }
      }
      plate
      model
      current_km
      daily_km
      extra_km
      status
      city
      driver
      driverPrice
      delivery
      deliveryPrice
      daily
      weekly
      monthly
      category
      fuel
      gearbox
      ac
      seats
      navigation
      insurance
    }
    customer_info {
      id
      company_info {
        id
        email
        call_center
        english_name
        arabic_name
        added_date
        documents {
          label
          description
          uploaded_by
          uploaded_for
          uploaded_date
          url
        }
      }
      email
      phone
      birth_date
      id_expiration
      license_expiration
      nationality
      occupation
      added_date
      status
      documents {
        label
        description
        uploaded_by
        uploaded_for
        uploaded_date
        url
      }
    }
    from
    to
    driver
    delivery
    location {
      lat
      lng
    }
    discount
    vat
    totalPrice
    status
  }
}
`;
