import { IonButtons, IonContent, IonHeader, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react';
import { Alert, Drawer } from 'antd';
import { graphqlOperation } from 'aws-amplify';
import { Connect } from 'aws-amplify-react';
import React, { useContext, useState } from 'react';
import { OnAddCarSubscription } from '../../API';
import { getCars } from '../../graphql/queries';
import { onAddCar } from '../../graphql/subscriptions';
import { CarType } from '../../services/declarations';
import UserContext from '../../services/userContext';
import { CarAdd } from './CarAdd';
import { CarsTable } from './CarsTable';

const CarsList = ({ addCar, editCar }) => {
	const user = useContext(UserContext);
	return (
		<Connect
			query={graphqlOperation(getCars, { company_id: user['custom:trade_id'] })}
			subscription={graphqlOperation(onAddCar, { company_id: user['custom:trade_id'] })}
			onSubscriptionMsg={(prev, data: OnAddCarSubscription) => {
				let edited = false;
				if (!data) return prev;
				for (let i = 0; i < prev.getCars.length; i++) {
					if (prev.getCars[i].plate === data.onAddCar!.plate) {
						prev.getCars[i] = data.onAddCar;
						edited = true;
						break;
					}
				}
				if (!edited) {
					prev.getCars.push(data.onAddCar);
				}
				return prev;
			}}>
			{(args) => {
				const { data, loading, error } = args;
				if (error || !data) return <h3>Error</h3>;
				return <CarsTable editCar={editCar} loading={loading} addCar={addCar} data={data.getCars} />;
			}}
		</Connect>
	);
};

const CarsPage: React.FunctionComponent = () => {
	const [ showAdd, setShowAdd ] = useState(false);
	const [ success, setSuccess ] = useState(false);
	const [ selected, setSelected ] = useState<CarType | undefined>(undefined);

	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Cars</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				<Alert
					closable
					onClose={() => setSuccess(false)}
					style={{ margin: '1rem', display: success ? 'block' : 'none' }}
					type='success'
					message='Successfully Added Car'
				/>

				<Drawer onClose={() => setShowAdd(false)} width='50%' visible={showAdd}>
					<CarAdd
						hide={() => setShowAdd(false)}
						success={() => {
							setSuccess(true);
						}}
						error={() => {
							setSuccess(false);
						}}
						initialData={selected}
					/>
				</Drawer>

				<CarsList
					editCar={(car) => {
						console.log(car);
						setSelected(car);
						setShowAdd(true);
					}}
					addCar={() => {
						setSelected(undefined);
						setShowAdd(true);
					}}
				/>
			</IonContent>
		</React.Fragment>
	);
};

export default CarsPage;
