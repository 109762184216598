import { Alert, Button, Checkbox, DatePicker, Icon, Input, Select, Spin, Upload } from 'antd';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { Field, Form, Formik } from 'formik';
import moment, { Moment } from 'moment';
import React, { useContext, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import * as Yup from 'yup';
import { addCustomer } from '../../graphql/mutations';
import { countries } from '../../services/countryList';
import { CustomerType, DocumentType } from '../../services/declarations';
import UserContext from '../../services/userContext';

interface Props {
	hide();
	success();
	error();
	initialData?: CustomerType;
}

export const CustomerAdd = (props: Props) => {
	const [ serverError, setServerError ] = useState(false);
	const [ files, setFiles ] = useState([] as any[]);

	const user = useContext(UserContext);

	const disableBeforeToday = (current) => {
		// Can not select days before today and today
		return current && current < moment().endOf('day');
	};
	const disableAfterToday = (current) => {
		// Can not select days before today and today
		return current && current > moment().endOf('day');
	};
	return (
		<div style={{ padding: '20px' }}>
			<h2>Add Customer</h2>
			<Alert
				closable
				onClose={() => setServerError(false)}
				style={{ margin: '1rem', display: serverError ? 'block' : 'none' }}
				type='error'
				message='Error Addeding Customer'
			/>
			<Formik
				initialValues={
					props.initialData ? (
						props.initialData
					) : (
						{
							id: '',
							occupation: '',
							email: '',
							phone: '',
							birth_date: undefined,
							id_expiration: undefined,
							license_expiration: undefined,
							nationality: '',
							status: 'verified',
							documents: [] as DocumentType[],
						}
					)
				}
				validationSchema={{
					id: Yup.string().required('ID is required'),
				}}
				enableReinitialize={true}
				render={({ errors, isSubmitting, setFieldValue, values, setFieldError }) => {
					return (
						<Form>
							<div className='form-group'>
								<label style={{ display: 'block' }}>Information</label>
								<Field
									name='id'
									render={(renderProps) => (
										<Input
											{...renderProps.field}
											disabled={props.initialData}
											placeholder='ID'
											style={{
												width: '16rem',
												textAlign: 'center',
												marginRight: '2rem',
												borderColor: errors.id ? '#dc3545' : '#ced4da',
											}}
										/>
									)}
								/>
								<Field
									name='occupation'
									render={(props) => (
										<Input
											{...props.field}
											placeholder='Occupation'
											style={{
												width: '16rem',
												textAlign: 'center',
												borderColor: errors.occupation ? '#dc3545' : '#ced4da',
											}}
										/>
									)}
								/>

								<div
									style={{
										display: errors.id || errors.occupation ? 'block' : 'none',
									}}
									className='invalid-feedback'>
									{errors.id} {errors.occupation}
								</div>
							</div>
							<div className='form-group'>
								<label style={{ display: 'block' }}>Contact</label>
								<Field
									name='phone'
									render={() => (
										<ReactPhoneInput
											// containerClass='custom-react-tel-div'
											containerStyle={{
												display: 'inline-table',
												width: '16rem',
												marginRight: '2rem',
											}}
											inputStyle={{
												width: '16rem',
												height: '32px',
											}}
											defaultCountry={'sa'}
											value={values.phone}
											onChange={(phone) => {
												setFieldValue('phone', phone);
											}}
										/>
									)}
								/>
								<Field
									name='email'
									render={(props) => (
										<Input
											{...props.field}
											placeholder='Email'
											style={{
												width: '16rem',
												textAlign: 'center',
												marginRight: '2rem',
												borderColor: errors.email ? '#dc3545' : '#ced4da',
											}}
										/>
									)}
								/>

								<div
									style={{
										display: errors.email || errors.phone ? 'block' : 'none',
									}}
									className='invalid-feedback'>
									{errors.email} {errors.phone}
								</div>
							</div>

							<div className='form-group'>
								<label style={{ display: 'block' }}>Birth Date</label>
								<Field
									name='birthdate'
									render={(props) => {
										return (
											<DatePicker
												{...props}
												onChange={(data: Moment) => {
													console.log(data.format('YYYY-MM-DD'));
													setFieldError('birth_date', '');
													setFieldValue('birth_date', data.format('YYYY-MM-DD'));
												}}
												format='YYYY-MM-DD'
												disabledDate={disableAfterToday}
												value={values.birth_date ? moment(values.birth_date) : undefined}
												style={{
													width: '16rem',
												}}
											/>
										);
									}}
								/>

								<div
									style={{
										display: errors.birth_date ? 'block' : 'none',
									}}
									className='invalid-feedback'>
									{errors.birth_date}
								</div>
							</div>
							<div className='form-group'>
								<label style={{ display: 'block' }}>ID Expiration</label>
								<Field
									name='id_expiration'
									render={(props) => {
										return (
											<DatePicker
												{...props}
												onChange={(data: Moment) => {
													console.log(data.format('YYYY-MM-DD'));
													setFieldError('id_expiration', '');
													setFieldValue('id_expiration', data.format('YYYY-MM-DD'));
												}}
												format='YYYY-MM-DD'
												disabledDate={disableBeforeToday}
												value={values.id_expiration ? moment(values.id_expiration) : undefined}
												style={{
													width: '16rem',
												}}
											/>
										);
									}}
								/>

								<div
									style={{
										display: errors.id_expiration ? 'block' : 'none',
									}}
									className='invalid-feedback'>
									{errors.id_expiration}
								</div>
							</div>
							<div className='form-group'>
								<label style={{ display: 'block' }}>License Expiration</label>
								<Field
									name='license_expiration'
									render={(props) => {
										return (
											<DatePicker
												{...props}
												onChange={(data: Moment) => {
													console.log(data.format('YYYY-MM-DD'));
													setFieldError('license_expiration', '');
													setFieldValue('license_expiration', data.format('YYYY-MM-DD'));
												}}
												value={
													values.license_expiration ? (
														moment(values.license_expiration)
													) : (
														undefined
													)
												}
												format='YYYY-MM-DD'
												disabledDate={disableBeforeToday}
												style={{
													width: '16rem',
												}}
											/>
										);
									}}
								/>

								<div
									style={{
										display: errors.license_expiration ? 'block' : 'none',
									}}
									className='invalid-feedback'>
									{errors.license_expiration}
								</div>
							</div>
							<div className='form-group'>
								<label>Nationality</label>
								<Field
									name='nationality'
									render={() => (
										<div>
											{countries.length === 0 ? (
												<Spin />
											) : (
												<Select
													showSearch
													style={{ display: 'block', width: '20rem' }}
													placeholder='Select a branch'
													onChange={(selected) => {
														setFieldError('nationality', '');
														setFieldValue('nationality', selected);
													}}
													value={values.nationality}>
													{countries.map((single) => {
														return (
															<Select.Option key={single} value={single}>
																{single}
															</Select.Option>
														);
													})}
												</Select>
											)}
										</div>
									)}
								/>

								<div
									style={{ display: errors.nationality ? 'block' : 'none' }}
									className='invalid-feedback'>
									{errors.nationality}
								</div>
							</div>

							<div className='form-group'>
								<label style={{ display: 'block', marginBottom: '0.5rem' }}>
									Please upload trade certificate and all other necessary files
								</label>
								<Upload
									name='file'
									multiple={true}
									onRemove={(file) => {
										const tempList = files.filter((single) => single.name !== file.name);
										setFiles(tempList);
									}}
									beforeUpload={(file) => {
										setFiles([ ...files, file ]);
										return false;
									}}>
									<Button>
										<Icon type='upload' /> Click to Select Files
									</Button>
								</Upload>

								<div
									className='invalid-feedback'
									style={{
										display: errors.documents ? 'block' : 'none',
									}}>
									You must upload at least one file
								</div>
							</div>
							<div className='form-group'>
								<Field name='status' type='text' style={{ display: 'none' }} />
								<Checkbox
									onChange={(event) => {
										setFieldValue('status', event.target.checked ? 'verified' : 'pending');
									}}
									style={{
										color: 'black',
										fontSize: '1rem',
									}}
									checked={values.status === 'verified'}>
									Set Verified
								</Checkbox>
							</div>

							<Button disabled={isSubmitting} type='primary' htmlType='submit' className='mr-2'>
								{isSubmitting ? 'Loading...' : props.initialData ? 'Update' : 'Add'}
							</Button>
							<Button hidden={isSubmitting} type='default' onClick={() => props.hide()}>
								Cancel
							</Button>
						</Form>
					);
				}}
				onSubmit={async (fields, action) => {
					console.log(fields);
					action.setErrors({});
					if (!fields.id) {
						console.log('Error in ID');
						action.setFieldError('id', 'You must enter customer ID');
						action.setSubmitting(false);
						return;
					} else if (!fields.occupation) {
						console.log('Error in Customer Occupation');
						action.setFieldError('occupation', 'Error in Customer Occupation');
						action.setSubmitting(false);
						return;
					} else if (!fields.email) {
						console.log('Error in Customer Email');
						action.setFieldError('email', 'Error in Customer Email');
						action.setSubmitting(false);
						return;
					} else if (
						!fields.phone.match(
							/(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s.]?[(]?[0-9]{1,3}[)]?([-\s.]?[0-9]{3})([-\s.]?[0-9]{3,4})/,
						)
					) {
						console.log('Error in Customer Phone');
						action.setFieldError('phone', 'Error in Customer Phone');
						action.setSubmitting(false);
						return;
					} else if (!fields.birth_date) {
						console.log('Error in Customer Birth Date');
						action.setFieldError('birth_date', 'Please enter Customer Birth Date');
						action.setSubmitting(false);
						return;
					} else if (!fields.id_expiration) {
						console.log('Error in Customer ID Expiration');
						action.setFieldError('id_expiration', 'Please enter Customer ID Expiration');
						action.setSubmitting(false);
						return;
					} else if (!fields.license_expiration) {
						console.log('Error in Customer License Expiration');
						action.setFieldError('license_expiration', 'Please enter Customer License Expiration');
						action.setSubmitting(false);
						return;
					} else if (!fields.nationality) {
						console.log('Error in Customer Nationality');
						action.setFieldError('nationality', 'Please enter Customer Nationality');
						action.setSubmitting(false);
						return;
					} else if (files.length === 0 && !props.initialData) {
						action.setFieldError('documents', 'Error');
						action.setSubmitting(false);
						return;
					}

					const docs = [] as DocumentType[];
					const today = new Date();
					const day = String(today.getDate()).padStart(2, '0');
					const month = String(today.getMonth() + 1).padStart(2, '0');
					const year = today.getFullYear();
					const added_date = year + '-' + month + '-' + day;

					for (let i = 0; i < files.length; i++) {
						const key = `${user['custom:trade_id']}-${user.email}-${files[i].name}`;
						await Storage.put(key, files[i]);
						const document: DocumentType = {
							uploaded_by: user.email,
							uploaded_for: user['custom:trade_id'],
							label: key,
							url: key,
							uploaded_date: added_date,
						};
						docs.push(document);
					}

					const customerData = {
						...fields,
						documents: [ ...fields.documents, ...docs ],
						company_id: user['custom:trade_id'],
					};
					console.log(customerData);
					Object.keys(customerData).forEach((key) => {
						if (!customerData[key] && typeof customerData[key] == 'string') {
							customerData[key] = '-';
						}
					});
					console.log(customerData);
					try {
						const res = await API.graphql(graphqlOperation(addCustomer, customerData));
						console.log(res);
						props.success();
						setServerError(false);
						action.resetForm();
						setFiles([]);
						props.hide();
					} catch (e) {
						console.log(e);
						props.error();
						setServerError(true);
					}
					action.setSubmitting(false);
				}}
			/>
		</div>
	);
};
