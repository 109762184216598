import { Alert, Button, Col, Modal, Row } from 'antd';
import { Auth } from 'aws-amplify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { ConfirmSignupModal } from './ConfirmSignupModal';

const LoginPage: React.FunctionComponent = () => {
	const [ showAlert, setShowAlert ] = useState(false);
	const [ showConfirmModal, setShowConfirmationModal ] = useState(false);
	const [ showForgotModal, setShowForgotModal ] = useState(false);
	const [ showForgotConfirmModal, setShowForgotConfirmModal ] = useState(false);
	const [ data, setData ] = useState({
		title: '',
		body: '',
		email: '',
	});
	return (
		<Row style={{ height: '100%', background: '#dcdcdc' }} type='flex' justify='space-around' align='middle'>
			<Col style={{ padding: '30px' }}>
				<div className='Logo' />
				<h4 style={{ margin: '10px 50px' }}>iNeed Console</h4>
				<Alert
					message={
						<div>
							<h4>{data.title}</h4>
							<p>{data.body}</p>
						</div>
					}
					type='error'
					showIcon={true}
					style={{ display: showAlert ? 'block' : 'none' }}
					onClose={() => setShowAlert(false)}
					closable
				/>
				<Formik
					enableReinitialize={true}
					initialValues={{
						email: data.email,
						password: '',
					}}
					validationSchema={Yup.object().shape({
						email: Yup.string().email('Email is invalid').required('Email is required'),
						password: Yup.string().required('Password is required'),
					})}
					render={({ errors, touched, isSubmitting, submitForm, resetForm, values }) => (
						<Form
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									submitForm();
								}
							}}>
							<div className='form-group'>
								<label htmlFor='email'>Email</label>
								<Field
									name='email'
									type='text'
									className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
								/>
								<ErrorMessage name='email' component='div' className='invalid-feedback' />
							</div>
							<div className='form-group'>
								<label htmlFor='password'>Password</label>
								<Button
									type='link'
									onClick={() => {
										setData({ ...data, email: values.email });
										setShowForgotModal(true);
									}}>
									forgot password?
								</Button>
								<Field
									name='password'
									type='password'
									className={
										'form-control' + (errors.password && touched.password ? ' is-invalid' : '')
									}
								/>
								<ErrorMessage name='password' component='div' className='invalid-feedback' />
							</div>
							<div className='form-group'>
								<Button
									disabled={isSubmitting}
									type='primary'
									className='mr-2'
									onClick={() => submitForm()}>
									{isSubmitting ? 'Loading..' : 'Login'}
								</Button>
								<Button onClick={() => resetForm()} hidden={isSubmitting} type='default'>
									Reset
								</Button>
							</div>
						</Form>
					)}
					onSubmit={async (fields, action) => {
						action.setSubmitting(true);
						try {
							await Auth.signIn({
								username: fields.email,
								password: fields.password,
							});
							window.open('/home', '_self');
						} catch (err) {
							action.setSubmitting(false);
							if (err.code === 'UserNotConfirmedException') {
								setShowConfirmationModal(true);
							} else if (err.code === 'UserNotFoundException') {
								action.setFieldError('email', 'Email not Signed up, please signup first');
							} else if (err.code === 'NotAuthorizedException') {
								setData({
									email: fields.email,
									title: 'Authentication Error',
									body: 'Error, incorrect email or password',
								});
								setShowAlert(true);
							} else {
								setData({
									email: fields.email,
									title: 'Connection Error',
									body: 'Error, please check your internet connection!',
								});
								setShowAlert(true);
							}
						}
						action.setSubmitting(false);
					}}
				/>

				<Button style={{ paddingLeft: '0' }} type='link' href='/register'>
					or Register here
				</Button>
				<ConfirmSignupModal
					email={data.email}
					show={showConfirmModal}
					// onHide={this.showModal(false)}
				/>
				<ForgotModal
					resetEmail={data.email}
					visible={showForgotModal}
					hide={() => {
						setShowForgotModal(false);
					}}
					success={(email: string) => {
						setData({ ...data, email });
						setShowForgotConfirmModal(true);
					}}
				/>
				<ForgotConfirmModal
					resetEmail={data.email}
					visible={showForgotConfirmModal}
					hide={() => {
						setShowForgotConfirmModal(false);
					}}
					success={(email: string) => {
						console.log(email);
						setData({ ...data, email });
						setShowForgotConfirmModal(false);
					}}
				/>
			</Col>
		</Row>
	);
};

const ForgotModal = ({ resetEmail, visible, hide, success }) => {
	return (
		<Modal centered title='Password Reset' onCancel={() => hide()} visible={visible} footer=''>
			<Formik
				validationSchema={{
					email: Yup.string().email('Please enter a valid email').required('Email is required'),
				}}
				initialValues={{ email: resetEmail }}
				enableReinitialize={true}
				render={({ errors, touched, submitForm, isSubmitting }) => (
					<Form>
						<h6>Please enter your account email</h6>
						<div className='form-group'>
							<label htmlFor='email'>Email</label>
							<Field
								disabled={isSubmitting}
								name='email'
								type='text'
								className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
							/>
							<ErrorMessage name='email' component='div' className='invalid-feedback' />
						</div>

						<Button
							type='primary'
							disabled={isSubmitting}
							onClick={async () => {
								submitForm();
							}}>
							{isSubmitting ? 'Sending...' : 'Send Code'}
						</Button>
					</Form>
				)}
				onSubmit={async (fields, action) => {
					console.log(fields);
					action.setFieldError('email', '');
					action.setSubmitting(true);
					try {
						await Auth.forgotPassword(fields.email);
						hide();
						success(fields.email);
					} catch (err) {
						console.log(err);
						if (err.code === 'UserNotFoundException') {
							action.setFieldError('email', 'Email not found, please enter a registerd email');
						} else if (err.code === 'LimitExceededException') {
							action.setFieldError('email', 'Attempt limit exceeded, please try after some time');
						} else {
							action.setFieldError('email', 'Error, please check your internet connection');
						}
					}
					action.setSubmitting(false);
				}}
			/>
		</Modal>
	);
};

const ForgotConfirmModal = ({ resetEmail, visible, hide, success }) => {
	const [ alert, setAlert ] = useState(false);
	const handleSuccess = () => {
		setAlert(true);
		setInterval(() => {
			success();
			hide();
		}, 5000);
	};
	return (
		<Modal centered title='Password Reset' onCancel={() => hide()} visible={visible} footer=''>
			<Alert
				message='Password has been changed successfully'
				type='success'
				showIcon
				closable
				style={{ display: alert ? 'block' : 'none' }}
			/>
			{!alert && (
				<Formik
					validationSchema={{
						email: Yup.string().email('Please enter a valid email').required('Email is required'),
						code: Yup.string().required('Code is required'),
					}}
					initialValues={{ email: resetEmail, code: '', password: '', confirmPassword: '' }}
					enableReinitialize={true}
					render={({ errors, touched, submitForm, isSubmitting }) => (
						<Form>
							<h6>Please enter the code we sent to your email and your new password</h6>
							<div className='form-group'>
								<label htmlFor='email'>Email</label>
								<Field
									disabled
									name='email'
									type='text'
									className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
								/>
								<ErrorMessage name='email' component='div' className='invalid-feedback' />
							</div>
							<div className='form-group'>
								<label htmlFor='email'>Code</label>
								<Field
									disabled={isSubmitting}
									name='code'
									type='text'
									className={'form-control' + (errors.code && touched.code ? ' is-invalid' : '')}
								/>
								<ErrorMessage name='code' component='div' className='invalid-feedback' />
							</div>
							<div className='form-group'>
								<label htmlFor='password'>Password</label>
								<Field
									disabled={isSubmitting}
									name='password'
									type='password'
									className={
										'form-control' + (errors.password && touched.password ? ' is-invalid' : '')
									}
								/>
								<div
									className='invalid-feedback'
									style={{ display: errors.password ? 'block' : 'none' }}>
									Must Contain 8 Characters<br />One Uppercase<br />One Lowercase<br />One Number<br />and
									one special case Character
								</div>
							</div>
							<div className='form-group'>
								<label htmlFor='confirmPassword'>Confirm Password</label>
								<Field
									disabled={isSubmitting}
									name='confirmPassword'
									type='password'
									className={
										'form-control' +
										(errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')
									}
								/>
								<ErrorMessage name='confirmPassword' component='div' className='invalid-feedback' />
							</div>

							<Button
								type='primary'
								onClick={async () => {
									submitForm();
								}}>
								{isSubmitting ? 'Submitting...' : 'Submit'}
							</Button>
						</Form>
					)}
					onSubmit={async (fields, action) => {
						console.log(fields);
						action.setSubmitting(true);
						try {
							if (
								!fields.password.match(
									/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
								)
							) {
								const error = { code: 'password' };
								throw error;
							} else if (fields.password !== fields.confirmPassword) {
								const error = { code: 'confirmPassword' };
								throw error;
							} else {
								action.setFieldError('password', '');
								action.setFieldError('confirmPassword', '');
							}
							await Auth.forgotPasswordSubmit(fields.email, fields.code, fields.password);
							action.setFieldError('code', '');
							handleSuccess();
						} catch (err) {
							console.log(err);
							if (err.code === 'CodeMismatchException') {
								action.setFieldError('code', 'Please check your email for the correct code');
							} else if (err.code === 'password') {
								action.setFieldError('password', 'Error');
							} else if (err.code === 'confirmPassword') {
								action.setFieldError('confirmPassword', 'Passwords must match');
							} else {
								action.setFieldError('email', 'Error, please check your internet connection');
							}
						}
						action.setSubmitting(false);
					}}
				/>
			)}
		</Modal>
	);
};

export default LoginPage;
