import React, { useState, useEffect } from 'react';
import { Select, Input } from 'antd';
import { makes, getModels } from '../../services/carList';

const MakeSelect = ({ onSelect, data }) => {
	return (
		<Select
			showSearch
			onChange={(selected) => {
				onSelect(selected);
			}}
			placeholder="Car Make"
			style={{ width: '12rem' }}
			value={data}
		>
			{makes.map((single) => {
				return (
					<Select.Option key={single} value={single}>
						{single}
					</Select.Option>
				);
			})}
		</Select>
	);
};

const ModelSelect = ({ onSelect, data }) => {
	return (
		<Select
			showSearch
			onChange={(data) => {
				onSelect(data as string);
			}}
			style={{ width: '12rem' }}
			placeholder="Car Model"
			disabled={!data.make}
			value={data.model}
		>
			{getModels(data.make).map((single) => {
				return (
					<Select.Option key={single} value={single}>
						{single.substring(data.make.length)}
					</Select.Option>
				);
			})}
		</Select>
	);
};

export const CarModelSelect = (props: { onChange(value: string); value: string }) => {
	const [ make, setMake ] = useState<string | undefined>('');
	const [ model, setModel ] = useState<string | undefined>('');
	const [ year, setYear ] = useState('');

	useEffect(
		() => {
			const value = props.value;
			if (value === '') {
				setModel(undefined);
				setMake(undefined);
				setYear('');
			} else {
				setMake(value.substring(0, value.indexOf(' ')));
				setModel(value.substring(0, value.length - 4));
				setYear(value.substring(value.length - 4));
			}
		},
		[ props.value ],
	);

	return (
		<div>
			<MakeSelect
				onSelect={(selected) => {
					if (selected === make) {
						return;
					}
					setModel(undefined);
					setMake(selected);
					props.onChange('');
				}}
				data={make}
			/>
			<ModelSelect
				onSelect={(selected) => {
					if (year.length === 4) {
						props.onChange(selected + ' ' + year);
					}
					setModel(selected);
				}}
				data={{ make, model }}
			/>

			<Input
				value={year}
				type="number"
				min={1900}
				max={new Date().getFullYear() + 2}
				defaultValue={new Date().getFullYear().toString()}
				onChange={(event) => {
					setYear(event.target.value);
					if (
						model &&
						parseInt(event.target.value) > 1900 &&
						parseInt(event.target.value) < new Date().getFullYear() + 2
					) {
						props.onChange(model + ' ' + event.target.value);
					} else {
						props.onChange('');
					}
				}}
				style={{ width: '8rem', textAlign: 'center' }}
				placeholder="Year"
			/>
		</div>
	);
};
