import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import { DayOfWeek } from './DayOfWeek';
import { HoursType } from '../../services/declarations';

interface Props {
	setTimings(data: HoursType[]);
	show: boolean;
	onHide(): void;
	initialData: HoursType[];
}

export const HoursModal = (props: Props) => {
	const [days, setDays] = useState<HoursType[]>(props.initialData);
	useEffect(
		() => {
			if (props.initialData) {
				setDays(props.initialData);
			}
		},
		[props.initialData, props.show],
	);
	const handleDataChange = (label: String, value: HoursType) => {
		const newDays = days.map((element) => {
			if (element.label === label) {
				return value;
			} else if (!element.enabled && value.enabled) {
				element = { ...value, label: element.label, enabled: false };
			}
			return element;
		});
		setDays(newDays);
	};
	return (
		<Modal
			style={{ minWidth: '50%' }}
			onCancel={() => {
				props.onHide();
			}}
			visible={props.show}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			title="Add Operational Hours"
			footer={[
				<Button
					type="primary"
					onClick={() => {
						props.setTimings(days);
						props.onHide();
					}}
				>
					Save
				</Button>,
				<Button key="back" onClick={props.onHide}>
					Cancel
				</Button>,
			]}
		>
			{days.map((dayOfWeek, index) => {
				return (
					<DayOfWeek
						key={dayOfWeek.label}
						updateData={(data) => handleDataChange(dayOfWeek.label, data)}
						parentData={dayOfWeek}
					/>
				);
			})}
		</Modal>
	);
};
