// tslint:disable
// this is an auto generated file. This will be overwritten

export const onAddBranch = `subscription OnAddBranch($company_id: String!) {
  onAddBranch(company_id: $company_id) {
    company_info {
      id
      email
      call_center
      english_name
      arabic_name
      added_date
      documents {
        label
        description
        uploaded_by
        uploaded_for
        uploaded_date
        url
      }
    }
    id
    english_name
    arabic_name
    added_date
    status
    city
    phone
    location {
      lat
      lng
    }
    hours {
      enabled
      label
      first_open
      first_close
      second_open
      second_close
      second_shift
      timings
    }
  }
}
`;
export const onAddCar = `subscription OnAddCar($company_id: String!) {
  onAddCar(company_id: $company_id) {
    company_info {
      id
      email
      call_center
      english_name
      arabic_name
      added_date
      documents {
        label
        description
        uploaded_by
        uploaded_for
        uploaded_date
        url
      }
    }
    branch_info {
      company_info {
        id
        email
        call_center
        english_name
        arabic_name
        added_date
        documents {
          label
          description
          uploaded_by
          uploaded_for
          uploaded_date
          url
        }
      }
      id
      english_name
      arabic_name
      added_date
      status
      city
      phone
      location {
        lat
        lng
      }
      hours {
        enabled
        label
        first_open
        first_close
        second_open
        second_close
        second_shift
        timings
      }
    }
    plate
    model
    current_km
    daily_km
    extra_km
    status
    city
    driver
    driverPrice
    delivery
    deliveryPrice
    daily
    weekly
    monthly
    category
    fuel
    gearbox
    ac
    seats
    navigation
    insurance
  }
}
`;
export const onAddCustomer = `subscription OnAddCustomer($company_id: String!) {
  onAddCustomer(company_id: $company_id) {
    id
    company_info {
      id
      email
      call_center
      english_name
      arabic_name
      added_date
      documents {
        label
        description
        uploaded_by
        uploaded_for
        uploaded_date
        url
      }
    }
    email
    phone
    birth_date
    id_expiration
    license_expiration
    nationality
    occupation
    added_date
    status
    documents {
      label
      description
      uploaded_by
      uploaded_for
      uploaded_date
      url
    }
  }
}
`;
