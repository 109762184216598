import { IonContent, IonHeader, IonIcon, IonList, IonMenu, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { AppPage } from '../services/declarations';
import { power } from 'ionicons/icons';
import { Auth } from 'aws-amplify';

import { List, ListItemIcon, ListItem, Theme, ListItemText } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

interface MenuProps extends RouteComponentProps {
	appPages: AppPage[];
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			color: 'black',
			'&$selected': {
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
				'&:hover': {
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
				},
			},
		},
		selected: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
	}),
);

const Menu: React.FunctionComponent<MenuProps> = ({ appPages }) => {
	const classes = useStyles();
	const [ name, setName ] = useState('iNeed');
	useEffect(
		() => {
			Auth.currentAuthenticatedUser().then((user) => {
				setName(user.attributes.name);
			});
		},
		[ name ],
	);
	return (
		<IonMenu contentId="main">
			<IonHeader>
				<IonToolbar>
					<IonTitle>{name}</IonTitle>

					<IonIcon
						style={{
							cursor: 'pointer',
							margin: '1rem',
							fontSize: '1.4em',
							color: '#be1e2d',
						}}
						slot="end"
						icon={power}
						onClick={() => {
							Auth.signOut();
							window.location.href = '/';
						}}
					/>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonList>
					<List component="nav" aria-labelledby="nested-list-subheader">
						{appPages.map((appPage) => {
							const isSelected = window.location.pathname === appPage.url;
							return (
								<ListItem
									classes={classes}
									component={Link}
									key={appPage.url}
									to={appPage.url}
									selected={isSelected}
								>
									<ListItemIcon classes={{ root: isSelected ? classes.selected : classes.root }}>
										{appPage.icon}
									</ListItemIcon>
									<ListItemText primary={appPage.title} />
								</ListItem>
							);
						})}
					</List>
				</IonList>
			</IonContent>
		</IonMenu>
	);
};

export default withRouter(Menu);
