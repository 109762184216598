import React from 'react';
import { IonApp } from '@ionic/react';

// i18n implementation
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './locale/en.json';
import arabic from './locale/ar.json';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports-dev';

import AppRouter from './AppRouter';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import './globals.scss';
import './theme/custom.scss';
import 'react-phone-input-2/dist/style.css';

import 'antd/dist/antd.less';

Amplify.configure(awsconfig);

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			en: {
				translation: english,
			},
			ar: {
				translation: arabic,
			},
		},
		lng: 'en',
		fallbackLng: 'en',

		interpolation: {
			escapeValue: false,
		},
	});

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#be1e2d',
		},
	},
});

declare global {
	interface Window {
		google: any;
	}
}

const App: React.FunctionComponent = () => {
	return (
		<ThemeProvider theme={theme}>
			<IonApp>
				<AppRouter />
			</IonApp>
		</ThemeProvider>
	);
};

export default App;
