import { Alert, Button, Col, Row } from 'antd';
import { Auth } from 'aws-amplify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import * as Yup from 'yup';
import { ConfirmSignupModal } from './ConfirmSignupModal';

const RegisterPage: React.FunctionComponent = () => {
	const [ showAlert, setShowAlert ] = useState(false);
	const [ showModal, setShowModal ] = useState(false);
	const [ data, setData ] = useState({
		title: '',
		body: '',
		email: '',
	});

	return (
		<Row
			style={{ height: '100%', background: '#dcdcdc', overflow: 'scroll' }}
			type='flex'
			justify='space-around'
			align='middle'>
			<Col style={{ padding: '30px' }}>
				<div className='Logo' />
				<h4 style={{ margin: '10px 50px' }}>Sign up with iNeed</h4>
				<Alert
					closable
					type='error'
					style={{ display: showAlert ? 'block' : 'none' }}
					showIcon={true}
					onClose={() => setShowAlert(true)}
					message={
						<div>
							<h4>{data.title}</h4>
							<p>{data.body}</p>
						</div>
					}
				/>
				<Formik
					initialValues={{
						phone: '',
						email: '',
						trade_id: '',
						name: '',
						password: '',
						confirmPassword: '',
					}}
					validationSchema={Yup.object().shape({
						trade_id: Yup.string().required('Trade ID is required'),
						name: Yup.string().required('Company Name is required'),
						phone: Yup.string().min(7, 'Error in Phone number').required('Phone is required'),
						email: Yup.string().email('Email is invalid').required('Email is required'),
						password: Yup.string().required('Password is required'),
						confirmPassword: Yup.string()
							.oneOf([ '', Yup.ref('password') ], 'Passwords must match')
							.required('Confirm Password is required'),
					})}
					render={({ errors, touched, isSubmitting, values, setFieldValue, setTouched }) => (
						<Form>
							<div className='form-group'>
								<label htmlFor='name'>Name</label>
								<Field
									name='name'
									type='text'
									className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
								/>
								<ErrorMessage name='name' component='div' className='invalid-feedback' />
							</div>
							<div className='form-group'>
								<label htmlFor='email'>Email</label>
								<Field
									name='email'
									type='text'
									className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
								/>
								<ErrorMessage name='email' component='div' className='invalid-feedback' />
							</div>
							<div className='form-group'>
								<label htmlFor='phone'>Phone Number</label>
								<Field
									name='phone'
									render={() => (
										<ReactPhoneInput
											defaultCountry={'sa'}
											value={values.phone}
											onChange={(phone) => {
												setFieldValue('phone', phone);
												// validateField('phone');
											}}
										/>
									)}
								/>
								<div
									className='invalid-feedback'
									style={{ display: errors.phone && touched.phone ? 'block' : 'none' }}>
									Error in phone number
								</div>
							</div>
							<div className='form-group'>
								<label htmlFor='trade_id'>Trade ID</label>
								<Field
									name='trade_id'
									type='text'
									className={
										'form-control' + (errors.trade_id && touched.trade_id ? ' is-invalid' : '')
									}
								/>
								<ErrorMessage name='trade_id' component='div' className='invalid-feedback' />
							</div>
							<div className='form-group'>
								<label htmlFor='password'>Password</label>
								<Field
									name='password'
									type='password'
									className={
										'form-control' + (errors.password && touched.password ? ' is-invalid' : '')
									}
								/>
								<div
									className='invalid-feedback'
									style={{ display: errors.password && touched.password ? 'block' : 'none' }}>
									Must Contain 8 Characters<br />One Uppercase<br />One Lowercase<br />One Number<br />and
									one special case Character
								</div>
							</div>
							<div className='form-group'>
								<label htmlFor='confirmPassword'>Confirm Password</label>
								<Field
									name='confirmPassword'
									type='password'
									className={
										'form-control' +
										(errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')
									}
								/>
								<ErrorMessage name='confirmPassword' component='div' className='invalid-feedback' />
							</div>
							<div className='form-group'>
								<Button disabled={isSubmitting} type='primary' className='mr-2' htmlType='submit'>
									{isSubmitting ? 'Loading..' : 'Register'}
								</Button>
								<Button hidden={isSubmitting} type='default' htmlType='reset'>
									Reset
								</Button>
							</div>
						</Form>
					)}
					onSubmit={(fields, action) => {
						action.setErrors({});
						if (
							!fields.password.match(
								/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
							)
						) {
							action.setFieldError('password', 'Error');
							return;
						} else if (fields.password !== fields.confirmPassword) {
							action.setFieldError('confirmPassword', 'Passwords must match');
							return;
						}
						setData({ ...data, email: fields.email });
						Auth.signUp({
							username: fields.email,
							password: fields.password,
							attributes: {
								email: fields.email,
								phone_number: fields.phone.startsWith('+') ? fields.phone : '+' + fields.phone,
								name: fields.name,
								'custom:trade_id': fields.trade_id,
							},
						})
							.then((res) => {
								console.log(res);
								setShowModal(true);
								// window.open('/login', '_self');
							})
							.catch((err) => {
								console.log(err);
								action.setSubmitting(false);
								if (err.code === 'UsernameExistsException') {
									action.setFieldError('email', 'Email Already Used!');
								} else {
									setData({
										...data,
										title: 'Connection Error',
										body: 'Error, please check your internet connection!',
									});
									setShowAlert(true);
								}
							});
					}}
				/>
				<Button style={{ paddingLeft: '0' }} type='link' href='/login'>
					Go to Login
				</Button>
				<ConfirmSignupModal
					email={data.email}
					show={showModal}
					onHide={() => {
						console.log('Trying to Hide');
					}}
					// onHide={this.showModal(false)}
				/>
			</Col>
		</Row>
	);
};

export default RegisterPage;
