import { IonButtons, IonContent, IonHeader, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react';
import { Alert, Drawer } from 'antd';
import { graphqlOperation } from 'aws-amplify';
import { Connect } from 'aws-amplify-react';
import React, { useContext, useState } from 'react';
import { OnAddBranchSubscription } from '../../API';
import * as queries from '../../graphql/queries';
import * as subscriptions from '../../graphql/subscriptions';
import UserContext from '../../services/userContext';
import { BranchAdd } from './BranchAdd';
import { BranchesTable } from './BranchesTable';

const BranchList = (props) => {
	const user = useContext(UserContext);
	return (
		<Connect
			query={graphqlOperation(queries.getBranches, { company_id: user['custom:trade_id'] })}
			subscription={graphqlOperation(subscriptions.onAddBranch, { company_id: user['custom:trade_id'] })}
			onSubscriptionMsg={(prev, data: OnAddBranchSubscription) => {
				let edited = false;
				if (!data.onAddBranch) return prev;
				for (let i = 0; i < prev.getBranches.length; i++) {
					if (prev.getBranches[i].id === data.onAddBranch!.id) {
						prev.getBranches[i] = data.onAddBranch;
						edited = true;
						break;
					}
				}
				if (!edited) {
					prev.getBranches.push(data.onAddBranch);
				}
				return prev;
			}}>
			{(args) => {
				const { data, loading, error } = args;
				if (error || !data) return <h3>Error</h3>;
				return <BranchesTable loading={loading} addBranch={props.addBranch} data={data} />;
			}}
		</Connect>
	);
};

const BranchesPage: React.FunctionComponent = () => {
	const [ showAdd, setShowAdd ] = useState(false);
	const [ success, setSuccess ] = useState(false);
	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Branches</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				<Alert
					message='Successfully Added Branch'
					closable
					onClose={() => setSuccess(false)}
					style={{ margin: '1rem', display: success ? 'block' : 'none' }}
					type='success'
					showIcon={true}
				/>
				{
					<Drawer width='50%' onClose={() => setShowAdd(false)} visible={showAdd}>
						<BranchAdd
							hide={() => setShowAdd(false)}
							success={() => {
								setSuccess(true);
							}}
							error={() => {
								setSuccess(false);
							}}
						/>
					</Drawer>
				}
				<BranchList addBranch={() => setShowAdd(true)} />
			</IonContent>
		</React.Fragment>
	);
};

export default BranchesPage;
