import React from 'react';
import { Checkbox, FormGroup, FormControlLabel, InputLabel, Select, MenuItem, FormControl } from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from 'antd';
import { HoursType } from '../../services/declarations';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
		},
		selectEmpty: {
			marginTop: theme.spacing(2),
		},
		label: {
			minWidth: '9rem',
		},
	}),
);
interface Props {
	parentData: HoursType;
	updateData(data: HoursType): any;
}
export const DayOfWeek = ({ parentData, updateData }: Props) => {
	const classes = useStyles();
	const options = [
		'00:00',
		'00:30',
		'01:00',
		'01:30',
		'02:00',
		'02:30',
		'03:00',
		'03:30',
		'04:00',
		'04:30',
		'05:00',
		'05:30',
		'06:00',
		'06:30',
		'07:00',
		'07:30',
		'08:00',
		'08:30',
		'09:00',
		'09:30',
		'10:00',
		'10:30',
		'11:00',
		'11:30',
		'12:00',
		'12:30',
		'13:00',
		'13:30',
		'14:00',
		'14:30',
		'15:00',
		'15:30',
		'16:00',
		'16:30',
		'17:00',
		'17:30',
		'18:00',
		'18:30',
		'19:00',
		'19:30',
		'20:00',
		'20:30',
		'21:00',
		'21:30',
		'22:00',
		'22:30',
		'23:00',
		'23:30',
	];
	return (
		<FormGroup row>
			<FormControlLabel
				className={classes.label}
				control={
					<Checkbox
						checked={parentData.enabled}
						onChange={(event) => {
							// if (!event.target.checked) {
							// 	setSecondShift(false);
							// }
							updateData({
								...parentData,
								enabled: event.target.checked,
							});
							// setEnabled(event.target.checked);
						}}
						color='primary'
					/>
				}
				label={parentData.label}
			/>
			<FormControl disabled={!parentData.enabled} className={classes.formControl}>
				<InputLabel>From</InputLabel>
				<Select
					value={parentData.first_open}
					onChange={(event) => {
						// setFirstOpen(event.target.value as string);
						updateData({
							...parentData,
							first_open: event.target.value as string,
						});
					}}>
					{options.map((time) => {
						return (
							<MenuItem key={time} value={time}>
								{time}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
			<FormControl disabled={!parentData.enabled} className={classes.formControl}>
				<InputLabel>To</InputLabel>
				<Select
					value={parentData.first_close}
					onChange={(event) => {
						// setFirstClose(event.target.value as string);
						updateData({
							...parentData,
							first_close: event.target.value as string,
						});
					}}>
					{options.map((time, index) => {
						if (index <= options.indexOf(parentData.first_open) || parentData.first_open === '-') {
							return null;
						}
						return (
							<MenuItem key={time} value={time}>
								{time}
							</MenuItem>
						);
					})}
					{parentData.first_open === '-' ? null : <MenuItem value={'00:00'}>00:00</MenuItem>}
				</Select>
			</FormControl>
			<Button
				type='link'
				hidden={parentData.second_shift}
				disabled={!parentData.enabled}
				onClick={() => {
					// setSecondShift(true);
					updateData({
						...parentData,
						second_shift: true,
					});
				}}>
				Add 2nd Shift >>
			</Button>
			<FormControl
				hidden={!parentData.second_shift}
				disabled={parentData.first_close === '00:00' || !parentData.enabled}
				className={classes.formControl}>
				<InputLabel>From</InputLabel>
				<Select
					value={parentData.second_open}
					onChange={(event) => {
						// setSecondOpen(event.target.value as string);
						updateData({
							...parentData,
							second_open: event.target.value as string,
						});
					}}>
					{options.map((time, index) => {
						if (index <= options.indexOf(parentData.first_close) || parentData.first_close === '-') {
							return null;
						}
						return (
							<MenuItem key={time} value={time}>
								{time}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
			<FormControl
				hidden={!parentData.second_shift}
				disabled={parentData.first_close === '00:00' || !parentData.enabled}
				className={classes.formControl}>
				<InputLabel>To</InputLabel>
				<Select
					value={parentData.second_close}
					onChange={(event) => {
						// setSecondClose(event.target.value as string)
						updateData({
							...parentData,
							second_close: event.target.value as string,
						});
					}}>
					{options.map((time, index) => {
						if (index <= options.indexOf(parentData.second_open) || parentData.second_open === '-') {
							return null;
						}
						return (
							<MenuItem key={time} value={time}>
								{time}
							</MenuItem>
						);
					})}
					{parentData.second_open === '-' ? null : <MenuItem value={'00:00'}>00:00</MenuItem>}
				</Select>
			</FormControl>
			<Button
				type='link'
				disabled={!parentData.enabled}
				hidden={!parentData.second_shift}
				onClick={() => {
					// setSecondShift(false);
					updateData({
						...parentData,
						second_shift: false,
					});
				}}>
				{'<<'}
			</Button>
		</FormGroup>
	);
};
