import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, Button, Alert } from 'antd';
import { Auth } from 'aws-amplify';

export function ConfirmSignupModal(props) {
	const [ success, setSuccess ] = useState(false);
	const [ coolDown, setCoolDown ] = useState(false);
	return (
		<Modal
			visible={props.show}
			title='Confirm Email'
			aria-labelledby='contained-modal-title-vcenter'
			style={{ minWidth: '30%' }}
			footer=''
			centered>
			<Alert
				style={{ display: success ? 'block' : 'none' }}
				type='success'
				message={
					<div>
						<h4>Successfully Signed up</h4>
						<p>
							You will now be redirected to <a href='/login'>login</a>
						</p>
					</div>
				}
			/>
			<p>Please Confirm your email by entering the code we sent to you</p>
			<Formik
				initialValues={{
					code: '',
					email: props.email,
				}}
				validationSchema={Yup.object().shape({
					code: Yup.string().required('Code is required').length(6, 'The code must be 6 characters'),
					email: Yup.string().email('Email is invalid').required('Email is required'),
				})}
				onSubmit={(fields, action) => {
					console.log(fields);
					Auth.confirmSignUp(fields.email, fields.code)
						.then((res) => {
							console.log(res);
							setSuccess(true);
							setInterval(() => {
								window.open('/login', '_self');
							}, 5000);
						})
						.catch((err) => {
							console.log(err);
							if (err.code === 'CodeMismatchException') {
								// action.setError('Check your internet connection');
								action.setFieldError('code', 'The entered code is not correct');
							} else {
								action.setFieldError('code', 'Please check your internet connection');
							}
							action.setSubmitting(false);
						});
				}}
				render={({ errors, touched, isSubmitting, submitForm }) => (
					<Form>
						<div className='form-group'>
							<label htmlFor='email'>Email</label>
							<Field
								disabled
								name='email'
								type='text'
								className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
							/>
							<ErrorMessage name='email' component='div' className='invalid-feedback' />
						</div>
						<div className='form-group'>
							<label htmlFor='code'>Code</label>
							<Field
								name='code'
								type='text'
								className={'form-control' + (errors.code && touched.code ? ' is-invalid' : '')}
							/>
							<ErrorMessage name='code' component='div' className='invalid-feedback' />
						</div>
						<div className='form-group'>
							<Button
								onClick={() => submitForm()}
								disabled={isSubmitting}
								type='primary'
								className='mr-2'>
								{isSubmitting ? 'Loading...' : 'Confirm'}
							</Button>
							<Button
								hidden={isSubmitting}
								type='default'
								disabled={coolDown}
								onClick={() => {
									setCoolDown(true);
									const n = setInterval(() => {
										setCoolDown(false);
										clearInterval(n);
									}, 60000);
									Auth.resendSignUp(props.email);
								}}>
								{coolDown ? `You can resend Again in 1 minute` : 'Resend Code'}
							</Button>
						</div>
					</Form>
				)}
			/>
		</Modal>
	);
}
