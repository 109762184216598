import { HoursType } from './declarations';

export const HoursInitialValues: HoursType[] = [
	{
		enabled: false,
		label: 'Sunday',
		first_close: '-',
		first_open: '-',
		second_close: '-',
		second_open: '-',
		second_shift: false,
		timings: '-',
	},
	{
		enabled: false,
		label: 'Monday',
		first_close: '-',
		first_open: '-',
		second_close: '-',
		second_open: '-',
		second_shift: false,
		timings: '-',
	},
	{
		enabled: false,
		label: 'Tuesday',
		first_close: '-',
		first_open: '-',
		second_close: '-',
		second_open: '-',
		second_shift: false,
		timings: '-',
	},
	{
		enabled: false,
		label: 'Wednesday',
		first_close: '-',
		first_open: '-',
		second_close: '-',
		second_open: '-',
		second_shift: false,
		timings: '-',
	},
	{
		enabled: false,
		label: 'Thursday',
		first_close: '-',
		first_open: '-',
		second_close: '-',
		second_open: '-',
		second_shift: false,
		timings: '-',
	},
	{
		enabled: false,
		label: 'Friday',
		first_close: '-',
		first_open: '-',
		second_close: '-',
		second_open: '-',
		second_shift: false,
		timings: '-',
	},
	{
		enabled: false,
		label: 'Saturday',
		first_close: '-',
		first_open: '-',
		second_close: '-',
		second_open: '-',
		second_shift: false,
		timings: '-',
	},
];
