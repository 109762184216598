import { Alert, Button, Checkbox, Input, Radio, Select, Spin } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { GetBranchesQuery } from '../../API';
import { addCar } from '../../graphql/mutations';
import { CarType } from '../../services/declarations';
import UserContext from '../../services/userContext';
import { CarModelSelect } from './CarModelSelect';
import { CarPlateSelect } from './CarPlateSelect';

interface Props {
	hide();
	success();
	error();
	initialData?: CarType;
}

export const CarAdd = (props: Props) => {
	const [ serverError, setServerError ] = useState(false);
	const [ loading, setLoading ] = useState(true);
	const branchesQuery = useRef<GetBranchesQuery>({} as GetBranchesQuery);
	const [ branchList, setBranchList ] = useState<typeof branchesQuery.current.getBranches>([]);

	const user = useContext(UserContext);
	useEffect(
		() => {
			const fetchBranches = async () => {
				const query = `query GetBranches($company_id: String!) {
					getBranches(company_id: $company_id) {
						id
						english_name
						arabic_name
						city
					}
				}
				`;
				const res = await API.graphql(graphqlOperation(query, { company_id: user['custom:trade_id'] }));
				branchesQuery.current = res.data;
				setBranchList(branchesQuery.current.getBranches);
				setLoading(false);
			};
			fetchBranches();
		},
		[ user ],
	);
	return (
		<div style={{ padding: '20px' }}>
			<h2>Add Car</h2>
			<Alert
				closable
				onClose={() => setServerError(false)}
				style={{ margin: '1rem', display: serverError ? 'block' : 'none' }}
				type='error'
				message='Error Addeding Car'
			/>
			<Formik
				initialValues={
					props.initialData ? (
						{ ...props.initialData, branch_id: props.initialData.branch_info.id }
					) : (
						{
							branch_id: '',
							plate: '',
							model: '',
							current_km: '',
							daily_km: '',
							extra_km: '',
							status: '',
							driver: false,
							delivery: false,
							driverPrice: '',
							deliveryPrice: '',
							daily: '',
							weekly: '',
							monthly: '',
							category: undefined,
							seats: undefined,
							fuel: '91',
							gearbox: 'automatic',
							ac: true,
							navigation: false,
							insurance: 'full',
						}
					)
				}
				validationSchema={{
					branch_id: Yup.string().required('Branch is required'),
					plate: Yup.string().matches(/[0-9]{4}[A-Z]{3}/, 'Plate is invalid').required('Plate is required'),
					model: Yup.string().required('Car Model is required'),
					current_km: Yup.number().required('Car Kilometers is required'),
					status: Yup.string(),
					driver: Yup.boolean(),
					delivery: Yup.boolean(),
					driverPrice: Yup.number(),
					deliveryPrice: Yup.number(),
					daily: Yup.number(),
					weekly: Yup.number(),
					monthly: Yup.number(),
				}}
				enableReinitialize={true}
				render={({ errors, touched, isSubmitting, submitForm, setFieldValue, values, setFieldError }) => {
					return (
						<Form>
							<div className='form-group'>
								<label>Branch</label>
								<Field
									name='branch_id'
									render={() => (
										<div>
											{loading ? (
												<Spin />
											) : branchList.length === 0 ? (
												<Alert
													message='No branches found, please add a branch first'
													type='error'
												/>
											) : (
												<Select
													style={{ display: 'block', width: '20rem' }}
													placeholder='Select a branch'
													onChange={(selected) => {
														setFieldError('branch_id', '');
														setFieldValue('branch_id', selected);
													}}
													value={values.branch_id}>
													{branchList.map((single) => {
														return (
															<Select.Option key={single.id} value={single.id}>
																{single.english_name} {single.arabic_name}
															</Select.Option>
														);
													})}
												</Select>
											)}
										</div>
									)}
								/>

								<div
									style={{ display: errors.branch_id ? 'block' : 'none' }}
									className='invalid-feedback'>
									{errors.branch_id}
								</div>
							</div>
							<div className='form-group'>
								<label>Plate</label>
								<Field
									name='plate'
									render={() => (
										<CarPlateSelect
											disabled={!!props.initialData}
											onChange={(plate) => {
												setFieldValue('plate', plate);
												setFieldError('plate', '');
											}}
											value={values.plate}
										/>
									)}
								/>
								<div style={{ display: errors.plate ? 'block' : 'none' }} className='invalid-feedback'>
									{errors.plate}
								</div>
							</div>
							<div className='form-group'>
								<label>Model</label>
								<Field
									name='model'
									render={() => (
										<CarModelSelect
											onChange={(data) => {
												setFieldValue('model', data);
												setFieldError('model', '');
											}}
											value={values.model}
										/>
									)}
								/>
								<div style={{ display: errors.model ? 'block' : 'none' }} className='invalid-feedback'>
									{errors.model}
								</div>
							</div>
							<div className='form-group'>
								<label style={{ display: 'block' }}>Kilometers</label>
								<Field
									name='current_km'
									render={() => {
										return (
											<Input
												onChange={(event) => {
													setFieldValue('current_km', event.target.value);
													setFieldError('daily_km', '');
												}}
												placeholder='Current'
												style={{ width: '8rem', textAlign: 'center' }}
												value={values.current_km}
											/>
										);
									}}
								/>
								<Field
									name='daily_km'
									render={() => {
										return (
											<Input
												onChange={(event) => {
													setFieldValue('daily_km', event.target.value);
													setFieldError('daily_km', '');
												}}
												placeholder='Max Daily'
												style={{ width: '8rem', textAlign: 'center' }}
												value={values.daily_km}
											/>
										);
									}}
								/>
								<Field
									name='extra_km'
									render={() => {
										return (
											<Input
												onChange={(event) => {
													setFieldValue('extra_km', event.target.value);
													setFieldError('daily_km', '');
												}}
												placeholder='Extra SR/km'
												style={{ width: '8rem', textAlign: 'center' }}
												value={values.extra_km}
											/>
										);
									}}
								/>

								<div
									style={{
										display: errors.daily_km ? 'block' : 'none',
									}}
									className='invalid-feedback'>
									{errors.daily_km}
								</div>
								{/* <ErrorMessage name="extra_km" component="div" className="invalid-feedback" /> */}
							</div>
							<div className='form-group'>
								<label style={{ display: 'block' }}>Price</label>
								<Field
									name='daily'
									render={() => (
										<Input
											placeholder='Daily'
											onChange={(event) => {
												setFieldValue('daily', event.target.value);
												setFieldError('daily', '');
											}}
											style={{ width: '8rem', textAlign: 'center' }}
											value={values.daily}
										/>
									)}
								/>
								<Field
									name='weekly'
									render={(props) => (
										<Input
											{...props.field}
											placeholder='Weekly'
											style={{ width: '8rem', textAlign: 'center' }}
											value={values.weekly}
										/>
									)}
								/>
								<Field
									name='monthly'
									render={(props) => (
										<Input
											{...props.field}
											placeholder='Monthly'
											style={{ width: '8rem', textAlign: 'center' }}
											value={values.monthly}
										/>
									)}
								/>

								<div
									style={{
										display: errors.daily ? 'block' : 'none',
									}}
									className='invalid-feedback'>
									{errors.daily}
								</div>
							</div>
							<div className='form-group'>
								<label style={{ display: 'block' }}>Type</label>
								<Select
									style={{ width: '8rem', textAlign: 'center' }}
									placeholder='Category'
									onChange={(data) => {
										setFieldValue('category', data);
										setFieldError('category', '');
									}}
									value={values.category}>
									{[ 'economy', 'sedan', 'family', 'luxury' ].map((value) => {
										return (
											<Select.Option key={value} value={value}>
												{value.toUpperCase()}
											</Select.Option>
										);
									})}
								</Select>
								<Select
									style={{ width: '8rem', textAlign: 'center' }}
									placeholder='Seats'
									onChange={(data) => {
										setFieldValue('seats', data);
									}}
									value={values.seats}>
									{[ '2', '3', '4', '5', '6', '7', '8', '9', '10' ].map((value) => {
										return (
											<Select.Option key={value} value={value}>
												{value}
											</Select.Option>
										);
									})}
								</Select>

								<div
									style={{
										display: errors.category ? 'block' : 'none',
									}}
									className='invalid-feedback'>
									{errors.category}
								</div>
							</div>
							<div className='form-group'>
								<label style={{ display: 'block' }}>AC</label>
								<Radio.Group
									onChange={(event) => {
										setFieldValue('ac', event.target.value);
									}}
									defaultValue={true}
									value={values.ac}>
									<Radio value={true}>Available</Radio>
									<Radio value={false}>Not Available</Radio>
								</Radio.Group>
							</div>
							<div className='form-group'>
								<label style={{ display: 'block' }}>Fuel Type</label>
								<Radio.Group
									onChange={(event) => {
										setFieldValue('fuel', event.target.value);
									}}
									defaultValue='91'
									value={values.fuel}>
									<Radio value='91'>Gasoline 91</Radio>
									<Radio value='95'>Gasoline 95</Radio>
									<Radio value='electric'>Electric</Radio>
									<Radio value='diesel'>Diesel</Radio>
									<Radio value='other'>Other</Radio>
								</Radio.Group>
							</div>
							<div className='form-group'>
								<label style={{ display: 'block' }}>Gearbox</label>
								<Radio.Group
									onChange={(event) => {
										setFieldValue('gearbox', event.target.value);
									}}
									defaultValue='automatic'
									value={values.gearbox}>
									<Radio value='automatic'>Automatic</Radio>
									<Radio value='manual'>Manual</Radio>
								</Radio.Group>
							</div>
							<div className='form-group'>
								<label style={{ display: 'block' }}>Navigation</label>
								<Radio.Group
									onChange={(event) => {
										setFieldValue('navigation', event.target.value);
									}}
									defaultValue={false}>
									<Radio value={true}>Available</Radio>
									<Radio value={false}>Not Available</Radio>
								</Radio.Group>
							</div>
							<div className='form-group'>
								<label style={{ display: 'block' }}>Insurance</label>
								<Radio.Group
									onChange={(event) => {
										setFieldValue('insurance', event.target.value);
									}}
									defaultValue='full'
									value={values.insurance}>
									<Radio value='full'>Full</Radio>
									<Radio value='thirdparty'>Third-Party</Radio>
								</Radio.Group>
							</div>
							<div className='form-group'>
								<Field name='delivery' type='text' style={{ display: 'none' }} />
								<Checkbox
									onChange={(event) => {
										// console.log(ev)
										setFieldValue('delivery', event.target.checked);
									}}
									style={{
										color: 'black',
										fontSize: '1rem',
									}}
									checked={values.delivery}>
									Available for Delivery
								</Checkbox>
								<Input
									placeholder='Price'
									hidden={!values.delivery}
									style={{ width: '8rem', textAlign: 'center' }}
									value={values.deliveryPrice}
									onChange={(event) => {
										setFieldValue('deliveryPrice', event.target.value);
									}}
								/>
							</div>
							<div className='form-group'>
								<Field name='driver' type='text' style={{ display: 'none' }} />
								<Checkbox
									onChange={(event) => {
										// console.log(ev)
										setFieldValue('driver', event.target.checked);
									}}
									style={{
										color: 'black',
										fontSize: '1rem',
									}}
									checked={values.driver}>
									Available with Driver
								</Checkbox>
								<Input
									placeholder='Price'
									hidden={!values.driver}
									style={{ width: '8rem', textAlign: 'center' }}
									value={values.driverPrice}
									onChange={(event) => {
										setFieldValue('driverPrice', event.target.value);
									}}
								/>
							</div>
							<div className='form-group'>
								<Field
									name='status'
									render={() => (
										<Checkbox
											onChange={(event) => {
												// console.log(ev)
												setFieldValue('status', event.target.checked ? 'active' : 'inactive');
											}}
											style={{
												color: 'black',
												fontSize: '1rem',
											}}
											checked={values.status === 'active'}>
											Set Active
										</Checkbox>
									)}
								/>
							</div>

							<Button disabled={isSubmitting} type='primary' htmlType='submit' className='mr-2'>
								{isSubmitting ? 'Loading...' : props.initialData ? 'Update' : 'Add'}
							</Button>
							<Button hidden={isSubmitting} type='default' onClick={() => props.hide()}>
								Cancel
							</Button>
						</Form>
					);
				}}
				onSubmit={async (fields, action) => {
					if (!fields.branch_id) {
						console.log('You must select a branch');
						action.setFieldError('branch_id', 'You must select a branch');
						action.setSubmitting(false);
						return;
					} else if (fields.plate.length < 7) {
						console.log('Error in Car Plate');
						action.setFieldError('plate', 'Error in Car Plate');
						action.setSubmitting(false);
						return;
					} else if (!fields.model) {
						console.log('Error in Car Model');
						action.setFieldError('model', 'Error in Car Model');
						action.setSubmitting(false);
						return;
					} else if (!fields.current_km) {
						action.setSubmitting(false);
						action.setFieldError('daily_km', 'You must enter Current Kilometers');
						return;
					} else if (!fields.daily_km) {
						action.setSubmitting(false);
						action.setFieldError('daily_km', 'You must enter Max Daily Kilometers');
						return;
					} else if (!fields.extra_km) {
						action.setSubmitting(false);
						action.setFieldError('daily_km', 'You must enter Price for Extra Kilometers');
						return;
					} else if (!fields.daily) {
						console.log('You must enter daily price');
						action.setFieldError('daily', 'You must enter daily price');
						action.setSubmitting(false);
						return;
					} else if (!fields.category) {
						console.log('You must select category');
						action.setFieldError('category', 'You must select category');
						action.setSubmitting(false);
						return;
					} else if (!fields.seats) {
						console.log('You must select seats');
						action.setFieldError('category', 'You must select seats');
						action.setSubmitting(false);
						return;
					}
					const selectedBranch = branchList.find((branch) => {
						return branch.id === fields.branch_id;
					});

					const carData = {
						...fields,
						current_km: parseFloat(fields.current_km) || 0,
						daily_km: parseFloat(fields.daily_km) || 0,
						extra_km: parseFloat(fields.extra_km) || 0,
						driverPrice: parseFloat(fields.driverPrice) || 0,
						deliveryPrice: parseFloat(fields.deliveryPrice) || 0,
						daily: parseFloat(fields.daily) || 0,
						weekly: parseFloat(fields.weekly) || 0,
						monthly: parseFloat(fields.monthly) || 0,
						seats: parseFloat(fields.seats) || 5,
						status: fields.status ? 'active' : 'inactive',
						company_id: user['custom:trade_id'],
						city: selectedBranch!.city,
					};
					Object.keys(carData).forEach((key) => {
						if (!carData[key] && typeof carData[key] == 'string') {
							carData[key] = '-';
						}
					});
					console.log(carData);
					try {
						const res = await API.graphql(graphqlOperation(addCar, carData));
						console.log(res);
						props.success();
						setServerError(false);
						action.resetForm();
						props.hide();
					} catch (e) {
						console.log(e);
						props.error();
						setServerError(true);
					}
					action.setSubmitting(false);
				}}
			/>
		</div>
	);
};
