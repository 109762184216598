import React, { useState, useEffect } from 'react';
import { Select, Input } from 'antd';

const plateOptions = [
	{ value: 'A', content: 'A ا' },
	{ value: 'B', content: 'B ب' },
	{ value: 'J', content: 'J ح' },
	{ value: 'D', content: 'D د' },
	{ value: 'R', content: 'R ر' },
	{ value: 'S', content: 'S س' },
	{ value: 'X', content: 'X ص' },
	{ value: 'T', content: 'T ط' },
	{ value: 'E', content: 'E ع' },
	{ value: 'G', content: 'G ق' },
	{ value: 'K', content: 'K ك' },
	{ value: 'L', content: 'L ل' },
	{ value: 'Z', content: 'Z م' },
	{ value: 'N', content: 'N ن' },
	{ value: 'H', content: 'H هـ' },
	{ value: 'U', content: 'U و' },
	{ value: 'V', content: 'V ى' },
];

const LetterSelect = ({ onSelect, data, disabled }) => {
	return (
		<Select
			disabled={disabled}
			value={data}
			onChange={(data) => {
				onSelect(data as string);
			}}
			style={{ width: '4rem' }}
		>
			{plateOptions.map((time) => {
				return (
					<Select.Option key={time.value} value={time.value}>
						{time.content}
					</Select.Option>
				);
			})}
		</Select>
	);
};

interface Props {
	onChange(value: string);
	value: string;
	disabled?: boolean;
}

export const CarPlateSelect = ({ onChange, value, disabled = false }: Props) => {
	const [ letters, setLetters ] = useState([ '', '', '' ]);
	const [ number, setNumber ] = useState('');
	useEffect(
		() => {
			if (value === '') {
				setLetters([ '', '', '' ]);
				setNumber('');
			} else {
				setLetters([ value.substr(4, 1), value.substr(5, 1), value.substr(6, 1) ]);
				setNumber(value.substr(0, 4));
			}
		},
		[ value ],
	);
	return (
		<Input.Group compact>
			<Input
				disabled={disabled}
				value={number}
				onChange={(event) => {
					const updated = event.target.value as string;
					if (letters.join('').length === 3) {
						onChange(updated.padStart(4, '0') + letters.join(''));
					}
					if (updated.length > 4) {
						setNumber(updated.substr(0, 4));
					} else {
						setNumber(updated);
					}
				}}
				style={{ width: 100, textAlign: 'center' }}
				placeholder="Number"
			/>

			{letters.map((element, index) => {
				return (
					<LetterSelect
						key={index}
						disabled={disabled}
						onSelect={(selected) => {
							const temp = letters.map((tempElement, tempIndex) => {
								if (index === tempIndex) {
									return selected;
								} else {
									return tempElement;
								}
							});
							if (number.length > 0) {
								onChange(number.padStart(4, '0') + temp.join(''));
							}
							setLetters(temp);
						}}
						data={element}
					/>
				);
			})}
		</Input.Group>
	);
};
