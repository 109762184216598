import { IconProps } from '@material-ui/core/Icon';
import { Descriptions, Drawer, Spin } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import MaterialTable, { Column } from 'material-table';
import React, { useState } from 'react';
import Img from 'react-image';
import { addCustomer } from '../../graphql/mutations';
import { CustomerType } from '../../services/declarations';
// import * as omitDeep from 'omit-deep';
interface Props {
	data: CustomerType[];
	addCustomer(): void;
	loading: boolean;
	editCustomer(customer: CustomerType): void;
}

export const CustomersTable = (props: Props) => {
	const [ loading, setLoading ] = useState(false);
	const [ showCustomer, setShowCustomer ] = useState<CustomerType | null>(null);

	const columns: Column<CustomerType>[] = [
		{ title: 'ID', field: 'id' },
		// { title: 'Date Added', field: 'added_date' },
		{ title: 'email', field: 'email' },
		{ title: 'Phone', field: 'phone' },
		{
			title: 'License Expiration',
			field: 'license_expiration',
			// lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
		},
	];
	const iconProps: IconProps = { color: 'primary' };
	const actions = [
		{
			icon: 'remove_red_eye',
			tooltip: 'View Customer Info',
			onClick: (event, rowData) => {
				setShowCustomer(rowData);
			},
			iconProps,
		},
		{
			icon: 'create',
			tooltip: 'Edit Customer Info',
			onClick: (event, rowData) => {
				console.log(rowData);
				const data = { ...rowData };
				props.editCustomer(data);
			},
			iconProps,
		},
		(rowData) => ({
			icon: 'pause',
			tooltip: 'Deactivate Customer',
			onClick: async () => {
				setLoading(true);
				await API.graphql(graphqlOperation(addCustomer, { ...rowData, status: 'blocked' }));
				setLoading(false);
			},
			hidden: rowData.status !== 'verified',
			iconProps,
		}),
		(rowData) => ({
			icon: 'play_arrow',
			tooltip: 'Activate Customer',
			onClick: async () => {
				setLoading(true);
				await API.graphql(graphqlOperation(addCustomer, { ...rowData, status: 'verified' }));
				setLoading(false);
			},
			hidden: rowData.status === 'verified',
			iconProps,
		}),
		{
			icon: 'add',
			tooltip: 'Add Customer',
			isFreeAction: true,
			onClick: props.addCustomer,
			iconProps,
		},
	];
	return (
		<React.Fragment>
			<MaterialTable
				style={{ margin: '1rem' }}
				title='List of Customers'
				columns={columns}
				data={props.data}
				actions={actions}
				options={{
					actionsColumnIndex: -1,
				}}
				isLoading={loading || props.loading}
			/>
			<Drawer width='50%' placement='right' visible={!!showCustomer} onClose={() => setShowCustomer(null)}>
				{!!showCustomer && (
					<div>
						{showCustomer.documents.length && (
							<Img
								src={showCustomer!.documents[0].url}
								loader={<Spin style={{ width: '100%' }} />}
								container={(children) => {
									return (
										<div style={{ padding: children.props.src ? '' : '7rem', textAlign: 'center' }}>
											{children}
										</div>
									);
								}}
							/>
						)}
						<Descriptions style={{ marginTop: '3rem' }} column={6} title='Customer Info' bordered>
							<Descriptions.Item span={3} label='ID'>
								{showCustomer.id}
							</Descriptions.Item>
							<Descriptions.Item span={3} label='Email'>
								{showCustomer.email}
							</Descriptions.Item>

							<Descriptions.Item span={3} label='Phone'>
								{showCustomer.phone}
							</Descriptions.Item>
							<Descriptions.Item span={3} label='Birthdate'>
								{showCustomer.birth_date}
							</Descriptions.Item>

							<Descriptions.Item span={3} label='ID Expiration'>
								{showCustomer.id_expiration}
							</Descriptions.Item>
							<Descriptions.Item span={3} label='License Expiration'>
								{showCustomer.license_expiration}
							</Descriptions.Item>

							<Descriptions.Item span={3} label='Occupation'>
								{showCustomer.occupation}
							</Descriptions.Item>
							<Descriptions.Item span={3} label='Join Date'>
								{showCustomer.added_date}
							</Descriptions.Item>

							<Descriptions.Item span={3} label='Nationality'>
								{showCustomer.nationality}
							</Descriptions.Item>
							<Descriptions.Item span={3} label='Status'>
								{showCustomer.status}
							</Descriptions.Item>
						</Descriptions>
					</div>
				)}
			</Drawer>
		</React.Fragment>
	);
};
