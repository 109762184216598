import { IconProps } from '@material-ui/core/Icon';
import { API, graphqlOperation } from 'aws-amplify';
import MaterialTable, { Column } from 'material-table';
import * as omitDeep from 'omit-deep';
import React, { useState } from 'react';
import { GetBranchesQuery } from '../../API';
import { updateBranch } from '../../graphql/mutations';
import { BranchType, HoursType, Place } from '../../services/declarations';
import { HoursInitialValues } from '../../services/initialValues';
import { HoursModal } from './HoursModal';
import { LocationModal } from './LocationModal';

interface Props {
	data: GetBranchesQuery;
	addBranch(): any;
	loading: boolean;
}

export const BranchesTable = (props: Props) => {
	const [ loading, setLoading ] = useState(false);
	const [ selected, setSelected ] = useState({
		company_id: '',
		branch_id: '',
		status: '',
	});
	const [ locationDialog, setLocationDialog ] = useState<{
		place: Place;
		show: boolean;
	}>({
		place: {
			city: '',
			location: {
				lat: 0,
				lng: 0,
			},
		},
		show: false,
	});

	const [ hoursDialog, setHoursDialog ] = useState<{
		hours: HoursType[];
		show: boolean;
	}>({
		show: false,
		hours: HoursInitialValues,
	});

	const columns: Column<BranchType>[] = [
		{ title: 'Name', field: 'english_name' },
		{ title: 'City', field: 'city' },
		{ title: 'Phone', field: 'phone' },
		{
			title: 'Status',
			field: 'status',
		},
	];
	const iconProps: IconProps = { color: 'primary' };
	const actions = [
		{
			icon: 'location_on',
			tooltip: 'Branch Location',
			onClick: (event, rowData) => {
				setSelected({
					company_id: rowData.company_id,
					branch_id: rowData.id,
					status: rowData.status,
				});
				setLocationDialog({
					place: {
						city: rowData.city,
						location: rowData.location,
					},
					show: true,
				});
			},
			iconProps,
		},
		{
			icon: 'access_time',
			tooltip: 'Branch Hours',
			onClick: (event, rowData) => {
				console.log(rowData);
				setSelected({
					company_id: rowData.company_id,
					branch_id: rowData.id,
					status: rowData.status,
				});
				setHoursDialog({
					hours: rowData.hours,
					show: true,
				});
			},
			iconProps,
		},
		(rowData) => ({
			icon: 'pause',
			tooltip: 'Deactivate Branch',
			onClick: async () => {
				setLoading(true);
				await API.graphql(
					graphqlOperation(updateBranch, {
						company_id: rowData.company_id,
						branch_id: rowData.id,
						status: 'inactive',
						city: rowData.city,
					}),
				);
				setLoading(false);
			},
			hidden: rowData.status !== 'active',
			iconProps,
		}),
		(rowData) => ({
			icon: 'play_arrow',
			tooltip: 'Activate Branch',
			onClick: async () => {
				setLoading(true);
				await API.graphql(
					graphqlOperation(updateBranch, {
						company_id: rowData.company_id,
						branch_id: rowData.id,
						status: 'active',
						city: rowData.city,
					}),
				);
				setLoading(false);
			},
			hidden: rowData.status === 'active',
			iconProps,
		}),
		{
			icon: 'add',
			tooltip: 'Add Branch',
			isFreeAction: true,
			onClick: props.addBranch,
			iconProps,
		},
	];
	return (
		<React.Fragment>
			<MaterialTable
				style={{ margin: '1rem' }}
				title='List of Branches'
				columns={columns}
				data={props.data.getBranches}
				actions={actions}
				options={{
					actionsColumnIndex: -1,
				}}
				isLoading={loading || props.loading}
			/>
			<LocationModal
				onHide={() => {
					setLocationDialog({
						place: locationDialog!.place,
						show: false,
					});
				}}
				setPlace={async (place) => {
					setLoading(true);
					await API.graphql(
						graphqlOperation(updateBranch, {
							company_id: selected.company_id,
							branch_id: selected.branch_id,
							location: place.location,
							city: place.city,
							status: selected.status,
						}),
					);
					setLoading(false);
				}}
				show={locationDialog!.show}
				place={locationDialog!.place}
			/>
			<HoursModal
				show={hoursDialog.show}
				onHide={() =>
					setHoursDialog({
						...hoursDialog,
						show: false,
					})}
				initialData={hoursDialog.hours}
				setTimings={async (data) => {
					setLoading(true);
					await API.graphql(
						graphqlOperation(updateBranch, {
							company_id: selected.company_id,
							branch_id: selected.branch_id,
							hours: omitDeep(data, [ '__typename' ]),
						}),
					);
					setLoading(false);
				}}
			/>
		</React.Fragment>
	);
};
