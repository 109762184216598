import { Alert, Button, Checkbox, Input } from 'antd';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import * as Yup from 'yup';
import * as mutations from '../../graphql/mutations';
import { HoursType } from '../../services/declarations';
import { HoursInitialValues } from '../../services/initialValues';
import { HoursModal } from './HoursModal';
import { LocationModal } from './LocationModal';

interface Props {
	hide();
	success();
	error();
}

export const BranchAdd = (props: Props) => {
	const [ showLocationDialog, setShowLocationDialog ] = useState(false);
	const [ showHoursDialog, setShowHoursDialog ] = useState(false);
	const [ serverError, setServerError ] = useState(false);
	return (
		<div style={{ padding: '20px' }}>
			<h2>Add Branch</h2>
			<p>Please enter data for new branch. Branches cannot be deleted, please ensure accurace of data.</p>
			<Alert
				message='Error Addeding Branch'
				closable
				onClose={() => setServerError(false)}
				type='error'
				style={{ margin: '1rem', display: serverError ? 'block' : 'none' }}
			/>
			<Formik
				initialValues={{
					english_name: '',
					arabic_name: '',
					phone: '',
					status: 'active',
					city: '',
					location: { lat: 0, lng: 0 },
					hours: HoursInitialValues,
					hoursText: '',
				}}
				validationSchema={Yup.object().shape({
					english_name: Yup.string().required('English Name is required'),
					arabic_name: Yup.string().required('Arabic Name is required'),
					phone: Yup.string()
						.matches(
							/(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s.]?[(]?[0-9]{1,3}[)]?([-\s.]?[0-9]{3})([-\s.]?[0-9]{3,4})/,
							'Phone number invalid',
						)
						.required('Phone is required'),
				})}
				render={({ errors, touched, isSubmitting, setFieldValue, values, setFieldError }) => (
					<Form>
						<div className='form-group'>
							<label style={{ display: 'block' }} htmlFor='name'>
								English Name
							</label>
							<Field
								name='english_name'
								render={() => {
									return (
										<Input
											onChange={(event) => {
												setFieldValue('english_name', event.target.value);
												setFieldError('english_name', '');
											}}
											placeholder='Don&#39;t include the word &#39;BRANCH&#39;'
											style={{ width: '30rem', textAlign: 'left' }}
											value={values.english_name}
										/>
									);
								}}
							/>

							<div
								style={{ display: errors.english_name && touched.english_name ? 'block' : 'none' }}
								className='invalid-feedback'>
								{errors.english_name}
							</div>
						</div>
						<div className='form-group'>
							<label style={{ display: 'block' }} htmlFor='name'>
								Arabic Name
							</label>
							<Field
								name='arabic_name'
								render={() => {
									return (
										<Input
											onChange={(event) => {
												setFieldValue('arabic_name', event.target.value);
												setFieldError('arabic_name', '');
											}}
											placeholder='Don&#39;t include the word &#39;BRANCH&#39;'
											style={{ width: '30rem', textAlign: 'left' }}
											value={values.arabic_name}
										/>
									);
								}}
							/>

							<div
								style={{ display: errors.arabic_name && touched.arabic_name ? 'block' : 'none' }}
								className='invalid-feedback'>
								{errors.arabic_name}
							</div>
						</div>
						<div className='form-group'>
							<label htmlFor='phone'>Phone Number</label>
							<Field
								name='phone'
								render={() => (
									<ReactPhoneInput
										defaultCountry={'sa'}
										value={values.phone}
										onChange={(phone) => {
											setFieldValue('phone', phone);
										}}
									/>
								)}
							/>
							<div
								className='invalid-feedback'
								style={{ display: errors.phone && touched.phone ? 'block' : 'none' }}>
								Error in phone number
							</div>
						</div>

						<div className='form-group'>
							<h6 style={{ marginBottom: '5px' }}>
								{values.city ? (
									`City: ${values.city}, Coordinates: ${values.location.lat}, ${values.location.lng}`
								) : (
									'Location Not Selected'
								)}
							</h6>
							<div style={{ display: errors.city ? 'block' : 'none' }} className='invalid-feedback'>
								{errors.city}
							</div>
							<Button size='small' onClick={() => setShowLocationDialog(true)} type='default'>
								Select Location
							</Button>

							<LocationModal
								setPlace={(place) => {
									setFieldValue('city', place.city);
									setFieldValue('location', { lat: place.location.lat, lng: place.location.lng });
								}}
								show={showLocationDialog}
								onHide={() => setShowLocationDialog(false)}
								place={
									values.city ? (
										{
											city: values.city,
											location: values.location,
										}
									) : null
								}
							/>
						</div>

						<div className='form-group'>
							<h6 style={{ marginBottom: '5px' }}>
								{values.hoursText ? `Hours: ${values.hoursText}` : 'Opening Hours not Added'}
							</h6>

							<div style={{ display: errors.hoursText ? 'block' : 'none' }} className='invalid-feedback'>
								{errors.hoursText}
							</div>
							<Button size='small' onClick={() => setShowHoursDialog(true)} type='default'>
								Add Openning Times
							</Button>

							<HoursModal
								setTimings={(data) => {
									setFieldValue('hours', data);
									let temp = '';
									Object.keys(data).forEach((day) => {
										const timingsData: HoursType = data[day];
										if (timingsData.enabled) {
											temp += `${timingsData.label
												.toUpperCase()
												.substring(
													0,
													3,
												)}: ${timingsData.first_open}-${timingsData.first_close}`;
											if (timingsData.second_shift) {
												temp += `, ${timingsData.second_open}-${timingsData.second_close}`;
											}
											temp += '  ';
										}
									});
									setFieldValue('hoursText', temp);
								}}
								show={showHoursDialog}
								onHide={() => {
									setShowHoursDialog(false);
								}}
								initialData={values.hours}
							/>
						</div>

						<div className='form-group'>
							<Field
								name='status'
								render={() => (
									<Checkbox
										onChange={(event) => {
											// console.log(ev)
											setFieldValue('status', event.target.checked ? 'active' : 'inactive');
										}}
										style={{
											color: 'black',
											fontSize: '1rem',
										}}
										checked={values.status === 'active'}>
										Set Active
									</Checkbox>
								)}
							/>
						</div>

						<div className='form-group'>
							<Button disabled={isSubmitting} type='primary' htmlType='submit' className='mr-2'>
								{isSubmitting ? 'Loading...' : 'Add'}
							</Button>
							<Button hidden={isSubmitting} type='default' onClick={() => props.hide()}>
								Cancel
							</Button>
						</div>
					</Form>
				)}
				onSubmit={async (fields, action) => {
					console.log(fields);
					try {
						if (fields.location.lat === 0 || fields.location.lng === 0 || fields.city === '') {
							console.log('location error');
							action.setFieldError('city', 'Branch Location is required');
							action.setSubmitting(false);
							return;
						}
						if (!fields.hoursText) {
							console.log('hours error');
							action.setFieldError('hoursText', 'Operational Hours field is required');
							action.setSubmitting(false);
							return;
						}
						action.setSubmitting(true);

						const user = await Auth.currentAuthenticatedUser();
						const branch_data = {
							company_id: user.attributes['custom:trade_id'],
							english_name: fields.english_name,
							arabic_name: fields.arabic_name,
							status: fields.status,
							phone: fields.phone,
							city: fields.city,
							location: fields.location,
							hours: fields.hours,
						};
						console.log(branch_data);
						await API.graphql(graphqlOperation(mutations.addBranch, branch_data));
						action.setSubmitting(false);
						action.resetForm();
						props.success();
						setServerError(false);
						props.hide();
					} catch (exception) {
						action.setSubmitting(false);
						setServerError(true);
						props.error();
						if (
							exception.errors &&
							exception.errors[0] &&
							exception.errors[0].message === 'DuplicateBranchName'
						) {
							action.setFieldError('name', 'branch name must be unique');
						}
						console.log(exception);
					}
				}}
			/>
		</div>
	);
};
