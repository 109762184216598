import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { UserProvider } from '../services/userContext';

export const PrivateRoute = (props) => {
	const [ user, setUser ] = useState<any>({});
	useEffect(() => {
		Auth.currentAuthenticatedUser({ bypassCache: true })
			.then((data) => {
				setUser(data.attributes);
			})
			.catch((err) => {
				console.log(err);
				setUser(null);
			});
	}, []);

	if (!user) {
		return <Redirect to={{ pathname: '/login' }} />;
	}
	if (Object.keys(user).length === 0) {
		return null;
	}
	return (
		<UserProvider value={user}>
			<Route path={props.path} component={props.component} exact={true} />
		</UserProvider>
	);
	// return Object.keys(user).length === 0 ? null : <Route path={props.path} component={props.component} exact={true} />;
};
