// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2019-09-18T07:17:23.368Z

interface IAWSAmplifyFederatedConfiguration {
    google_client_id?: string;
    facebook_app_id?: string;
    amazon_client_id?: string;
}

interface IAWSAmplifyCloudLogicConfiguration {
    [index: number]: {
        endpoint: string;
        name: string;
        region: string;
    };
}

interface IAWSAmplifyConfiguration {
    aws_appsync_authenticationType?: string;
    aws_appsync_graphqlEndpoint?: string;
    aws_appsync_region?: string;
    aws_cognito_identity_pool_id?: string;
    aws_cognito_region?: string;
    aws_cloud_logic_custom?: IAWSAmplifyCloudLogicConfiguration;
    aws_project_region: string;
    aws_user_files_s3_bucket?: string;
    aws_user_files_s3_bucket_region?: string;
    aws_user_pools_id?: string;
    aws_user_pools_web_client_id?: string;
    aws_user_pools_web_client_secret?: string;
    federated?: IAWSAmplifyFederatedConfiguration;
}

const awsmobile: IAWSAmplifyConfiguration = {
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_graphqlEndpoint: 'https://xpt26uf2x5gu3lxrtzr7q2g2cy.appsync-api.ap-south-1.amazonaws.com/graphql',
    aws_appsync_region: 'ap-south-1',
    aws_cognito_identity_pool_id: 'ap-south-1:b65de65e-e065-457a-8792-fb778e893911',
    aws_cognito_region: 'ap-south-1',
    aws_project_region: 'ap-south-1',
    aws_user_files_s3_bucket: 'ineed-dev-resources',
    aws_user_files_s3_bucket_region: 'ap-south-1',
    aws_user_pools_id: 'ap-south-1_c2lOOsWMR',
    aws_user_pools_web_client_id: '1nrhq4fmbk3561aph5b58cr1f0'
};

export default awsmobile;
