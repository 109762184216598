import { IconProps } from '@material-ui/core/Icon';
import { Descriptions, Drawer, Spin } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import MaterialTable, { Column } from 'material-table';
import React, { useState } from 'react';
import Img from 'react-image';
import { addCar } from '../../graphql/mutations';
import { CarType } from '../../services/declarations';
// import * as omitDeep from 'omit-deep';

interface Props {
	data: CarType[];
	addCar(): void;
	loading: boolean;
	editCar(car: CarType): void;
}

export const CarsTable = (props: Props) => {
	const [ loading, setLoading ] = useState(false);
	const [ showCar, setShowCar ] = useState<CarType | undefined>(undefined);

	const columns: Column<CarType>[] = [
		{ title: 'Plate', field: 'plate' },
		// { title: 'Date Added', field: 'added_date' },
		{ title: 'Branch', field: 'branch_info', render: (rowData) => rowData.branch_info.arabic_name },
		{ title: 'Model', field: 'model' },
		{
			title: 'Daily Price',
			field: 'daily',
			// lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
		},
	];
	const iconProps: IconProps = { color: 'primary' };
	const actions = [
		{
			icon: 'remove_red_eye',
			tooltip: 'View Car Info',
			onClick: (event, rowData) => {
				setShowCar(rowData);
			},
			iconProps,
		},
		{
			icon: 'create',
			tooltip: 'Edit Car Info',
			onClick: (event, rowData) => {
				console.log(rowData);
				const data = { ...rowData };
				props.editCar(data);
			},
			iconProps,
		},
		(rowData) => ({
			icon: 'pause',
			tooltip: 'Deactivate Car',
			onClick: async () => {
				setLoading(true);
				await API.graphql(graphqlOperation(addCar, { ...rowData, status: 'inactive' }));
				setLoading(false);
			},
			hidden: rowData.status !== 'active',
			iconProps,
		}),
		(rowData) => ({
			icon: 'play_arrow',
			tooltip: 'Activate Car',
			onClick: async () => {
				setLoading(true);
				await API.graphql(graphqlOperation(addCar, { ...rowData, status: 'active' }));
				setLoading(false);
			},
			hidden: rowData.status === 'active',
			iconProps,
		}),
		{
			icon: 'add',
			tooltip: 'Add Car',
			isFreeAction: true,
			onClick: props.addCar,
			iconProps,
		},
	];
	return (
		<React.Fragment>
			<MaterialTable
				style={{ margin: '1rem' }}
				title='List of Cars'
				columns={columns}
				data={props.data}
				actions={actions}
				options={{
					actionsColumnIndex: -1,
				}}
				isLoading={loading || props.loading}
			/>
			<Drawer width='50%' placement='right' visible={!!showCar} onClose={() => setShowCar(undefined)}>
				{!!showCar && (
					<div>
						<Img
							src={`https://apps-resources.s3.amazonaws.com/car_pics/${showCar!.model}.png`}
							loader={<Spin style={{ width: '100%' }} />}
							container={(children) => {
								return (
									<div style={{ padding: children.props.src ? '' : '7rem', textAlign: 'center' }}>
										{children}
									</div>
								);
							}}
						/>
						<Descriptions style={{ marginTop: '3rem' }} column={6} title='Car Info' bordered>
							<Descriptions.Item span={3} label='Plate'>
								{showCar.plate}
							</Descriptions.Item>
							<Descriptions.Item span={3} label='Model'>
								{showCar.model}
							</Descriptions.Item>

							<Descriptions.Item span={3} label='Branch'>
								{showCar.branch_info.english_name}
							</Descriptions.Item>
							<Descriptions.Item span={3} label='Status'>
								{showCar.status}
							</Descriptions.Item>

							<Descriptions.Item span={2} label='Current km'>
								{showCar.current_km}
							</Descriptions.Item>
							<Descriptions.Item span={2} label='Daily km'>
								{showCar.daily_km}
							</Descriptions.Item>
							<Descriptions.Item span={2} label='Extra km'>
								{showCar.extra_km} SR/km
							</Descriptions.Item>

							<Descriptions.Item span={2} label='Daily Price'>
								{showCar.daily} SR
							</Descriptions.Item>
							<Descriptions.Item span={2} label='Weekly Price'>
								{showCar.weekly} SR
							</Descriptions.Item>
							<Descriptions.Item span={2} label='Monthly Price'>
								{showCar.monthly} SR
							</Descriptions.Item>

							<Descriptions.Item span={showCar.delivery ? 3 : 6} label='Delivery'>
								{showCar.delivery ? 'Available' : 'Not Available'}
							</Descriptions.Item>
							{showCar.delivery && (
								<Descriptions.Item span={3} label='Driver Price'>
									{showCar.deliveryPrice}
								</Descriptions.Item>
							)}
							<Descriptions.Item span={showCar.driver ? 3 : 6} label='Driver'>
								{showCar.driver ? 'Available' : 'Not Available'}
							</Descriptions.Item>
							{showCar.driver && (
								<Descriptions.Item span={3} label='Driver Price'>
									{showCar.driverPrice}
								</Descriptions.Item>
							)}

							<Descriptions.Item span={2} label='Category'>
								{showCar.category!.toUpperCase()}
							</Descriptions.Item>
							<Descriptions.Item span={2} label='Seats'>
								{showCar.seats}
							</Descriptions.Item>
							<Descriptions.Item span={2} label='Gas Type'>
								{showCar.fuel}
							</Descriptions.Item>

							<Descriptions.Item span={2} label='AC'>
								{showCar.ac ? 'Available' : 'Not Available'}
							</Descriptions.Item>
							<Descriptions.Item span={2} label='Gearbox'>
								{showCar.gearbox.toUpperCase()}
							</Descriptions.Item>
							<Descriptions.Item span={2} label='Navigation'>
								{showCar.navigation ? 'Available' : 'Not Available'}
							</Descriptions.Item>

							<Descriptions.Item span={6} label='Insurance'>
								{showCar.insurance === 'full' ? 'Full Insurance' : 'Third-Party Insurance'}
							</Descriptions.Item>
						</Descriptions>
					</div>
				)}
			</Drawer>
		</React.Fragment>
	);
};
