import {
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonList,
	IonMenuButton,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import {
	americanFootball,
	basketball,
	beer,
	bluetooth,
	boat,
	build,
	flask,
	football,
	paperPlane,
	wifi,
} from 'ionicons/icons';
import React from 'react';

const ReservationList: React.FunctionComponent = () => {
	const icons = [ flask, wifi, beer, football, basketball, paperPlane, americanFootball, boat, bluetooth, build ];

	const items = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ].map((x) => {
		return (
			<IonItem key={x}>
				<IonIcon icon={icons[x - 1]} slot='start' />
				Item {x}
				<div className='item-note' slot='end'>
					This is item # {x}
				</div>
			</IonItem>
		);
	});

	return <IonList>{items}</IonList>;
};
const ReservationsPage: React.FunctionComponent = () => {
	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Reservations</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				<ReservationList />
			</IonContent>
		</React.Fragment>
	);
};

export default ReservationsPage;
