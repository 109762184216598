import { IonButtons, IonContent, IonHeader, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react';
import { Alert, Drawer } from 'antd';
import { graphqlOperation } from 'aws-amplify';
import { Connect } from 'aws-amplify-react';
import React, { useContext, useState } from 'react';
import { OnAddCustomerSubscription } from '../../API';
import { getCustomers } from '../../graphql/queries';
import { onAddCustomer } from '../../graphql/subscriptions';
import { CustomerType } from '../../services/declarations';
import UserContext from '../../services/userContext';
import { CustomerAdd } from './CustomerAdd';
import { CustomersTable } from './CustomersTable';

const CustomersList = ({ addCustomer, editCustomer }) => {
	const user = useContext(UserContext);
	// const t = useTranslation('');
	return (
		<Connect
			query={graphqlOperation(getCustomers, { company_id: user['custom:trade_id'] })}
			subscription={graphqlOperation(onAddCustomer, { company_id: user['custom:trade_id'] })}
			onSubscriptionMsg={(prev, data: OnAddCustomerSubscription) => {
				let edited = false;
				if (!data) return prev;
				for (let i = 0; i < prev.getCustomers.length; i++) {
					if (prev.getCustomers[i].id === data.onAddCustomer!.id) {
						prev.getCustomers[i] = data.onAddCustomer;
						edited = true;
						break;
					}
				}
				if (!edited) {
					prev.getCustomers.push(data.onAddCustomer);
				}
				return prev;
			}}>
			{(args) => {
				const { data, loading, error } = args;
				if (error || !data) return <h3>Error</h3>;
				return (
					<CustomersTable
						editCustomer={editCustomer}
						loading={loading}
						addCustomer={addCustomer}
						data={data.getCustomers}
					/>
				);
			}}
		</Connect>
	);
};

const CustomersPage: React.FunctionComponent = () => {
	const [ showAdd, setShowAdd ] = useState(false);
	const [ success, setSuccess ] = useState(false);
	const [ selected, setSelected ] = useState<CustomerType | undefined>(undefined);

	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Customers</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				<Alert
					closable
					onClose={() => setSuccess(false)}
					style={{ margin: '1rem', display: success ? 'block' : 'none' }}
					type='success'
					message='Successfully Added Customer'
				/>

				<Drawer onClose={() => setShowAdd(false)} width='50%' visible={showAdd}>
					<CustomerAdd
						hide={() => setShowAdd(false)}
						success={() => {
							setSuccess(true);
						}}
						error={() => {
							setSuccess(false);
						}}
						initialData={selected}
					/>
				</Drawer>

				<CustomersList
					editCustomer={(car) => {
						console.log(car);
						setSelected(car);
						setShowAdd(true);
					}}
					addCustomer={() => {
						setSelected(undefined);
						setShowAdd(true);
					}}
				/>
			</IonContent>
		</React.Fragment>
	);
};

export default CustomersPage;
