// tslint:disable
// this is an auto generated file. This will be overwritten

export const addCompany = `mutation AddCompany(
  $id: String!
  $email: String!
  $call_center: String!
  $english_name: String!
  $arabic_name: String!
  $documents: [DocumentInput!]!
) {
  addCompany(
    id: $id
    email: $email
    call_center: $call_center
    english_name: $english_name
    arabic_name: $arabic_name
    documents: $documents
  ) {
    id
    email
    call_center
    english_name
    arabic_name
    added_date
    documents {
      label
      description
      uploaded_by
      uploaded_for
      uploaded_date
      url
    }
  }
}
`;
export const addBranch = `mutation AddBranch(
  $company_id: String!
  $english_name: String!
  $arabic_name: String!
  $status: String!
  $city: String!
  $phone: String!
  $location: LocationInput!
  $hours: [HoursInput!]!
) {
  addBranch(
    company_id: $company_id
    english_name: $english_name
    arabic_name: $arabic_name
    status: $status
    city: $city
    phone: $phone
    location: $location
    hours: $hours
  ) {
    company_info {
      id
      email
      call_center
      english_name
      arabic_name
      added_date
      documents {
        label
        description
        uploaded_by
        uploaded_for
        uploaded_date
        url
      }
    }
    id
    english_name
    arabic_name
    added_date
    status
    city
    phone
    location {
      lat
      lng
    }
    hours {
      enabled
      label
      first_open
      first_close
      second_open
      second_close
      second_shift
      timings
    }
  }
}
`;
export const updateBranch = `mutation UpdateBranch(
  $company_id: String!
  $branch_id: String!
  $location: LocationInput
  $city: String
  $status: String
  $hours: [HoursInput]
) {
  updateBranch(
    company_id: $company_id
    branch_id: $branch_id
    location: $location
    city: $city
    status: $status
    hours: $hours
  ) {
    company_info {
      id
      email
      call_center
      english_name
      arabic_name
      added_date
      documents {
        label
        description
        uploaded_by
        uploaded_for
        uploaded_date
        url
      }
    }
    id
    english_name
    arabic_name
    added_date
    status
    city
    phone
    location {
      lat
      lng
    }
    hours {
      enabled
      label
      first_open
      first_close
      second_open
      second_close
      second_shift
      timings
    }
  }
}
`;
export const addCar = `mutation AddCar(
  $company_id: String!
  $branch_id: String!
  $plate: String!
  $model: String!
  $current_km: Float!
  $daily_km: Float!
  $extra_km: Float!
  $status: String!
  $city: String!
  $driver: Boolean!
  $driverPrice: Float!
  $delivery: Boolean!
  $deliveryPrice: Float!
  $daily: Float!
  $weekly: Float!
  $monthly: Float!
  $category: String!
  $fuel: String!
  $gearbox: String!
  $ac: String!
  $seats: Int!
  $navigation: String!
  $insurance: String!
) {
  addCar(
    company_id: $company_id
    branch_id: $branch_id
    plate: $plate
    model: $model
    current_km: $current_km
    daily_km: $daily_km
    extra_km: $extra_km
    status: $status
    city: $city
    driver: $driver
    driverPrice: $driverPrice
    delivery: $delivery
    deliveryPrice: $deliveryPrice
    daily: $daily
    weekly: $weekly
    monthly: $monthly
    category: $category
    fuel: $fuel
    gearbox: $gearbox
    ac: $ac
    seats: $seats
    navigation: $navigation
    insurance: $insurance
  ) {
    company_info {
      id
      email
      call_center
      english_name
      arabic_name
      added_date
      documents {
        label
        description
        uploaded_by
        uploaded_for
        uploaded_date
        url
      }
    }
    branch_info {
      company_info {
        id
        email
        call_center
        english_name
        arabic_name
        added_date
        documents {
          label
          description
          uploaded_by
          uploaded_for
          uploaded_date
          url
        }
      }
      id
      english_name
      arabic_name
      added_date
      status
      city
      phone
      location {
        lat
        lng
      }
      hours {
        enabled
        label
        first_open
        first_close
        second_open
        second_close
        second_shift
        timings
      }
    }
    plate
    model
    current_km
    daily_km
    extra_km
    status
    city
    driver
    driverPrice
    delivery
    deliveryPrice
    daily
    weekly
    monthly
    category
    fuel
    gearbox
    ac
    seats
    navigation
    insurance
  }
}
`;
export const addCustomer = `mutation AddCustomer(
  $company_id: String!
  $id: String!
  $email: String!
  $phone: String!
  $birth_date: String!
  $id_expiration: String!
  $license_expiration: String!
  $nationality: String!
  $occupation: String!
  $status: String!
  $documents: [DocumentInput!]
) {
  addCustomer(
    company_id: $company_id
    id: $id
    email: $email
    phone: $phone
    birth_date: $birth_date
    id_expiration: $id_expiration
    license_expiration: $license_expiration
    nationality: $nationality
    occupation: $occupation
    status: $status
    documents: $documents
  ) {
    id
    company_info {
      id
      email
      call_center
      english_name
      arabic_name
      added_date
      documents {
        label
        description
        uploaded_by
        uploaded_for
        uploaded_date
        url
      }
    }
    email
    phone
    birth_date
    id_expiration
    license_expiration
    nationality
    occupation
    added_date
    status
    documents {
      label
      description
      uploaded_by
      uploaded_for
      uploaded_date
      url
    }
  }
}
`;
export const addReservation = `mutation AddReservation(
  $company_id: String!
  $branch_id: String!
  $car_id: String!
  $customer_id: String!
  $from: String!
  $to: String!
  $discount: Float!
  $vat: Float!
  $totalPrice: Float!
  $status: String!
) {
  addReservation(
    company_id: $company_id
    branch_id: $branch_id
    car_id: $car_id
    customer_id: $customer_id
    from: $from
    to: $to
    discount: $discount
    vat: $vat
    totalPrice: $totalPrice
    status: $status
  ) {
    id
    company_info {
      id
      email
      call_center
      english_name
      arabic_name
      added_date
      documents {
        label
        description
        uploaded_by
        uploaded_for
        uploaded_date
        url
      }
    }
    branch_info {
      company_info {
        id
        email
        call_center
        english_name
        arabic_name
        added_date
        documents {
          label
          description
          uploaded_by
          uploaded_for
          uploaded_date
          url
        }
      }
      id
      english_name
      arabic_name
      added_date
      status
      city
      phone
      location {
        lat
        lng
      }
      hours {
        enabled
        label
        first_open
        first_close
        second_open
        second_close
        second_shift
        timings
      }
    }
    car_info {
      company_info {
        id
        email
        call_center
        english_name
        arabic_name
        added_date
        documents {
          label
          description
          uploaded_by
          uploaded_for
          uploaded_date
          url
        }
      }
      branch_info {
        company_info {
          id
          email
          call_center
          english_name
          arabic_name
          added_date
          documents {
            label
            description
            uploaded_by
            uploaded_for
            uploaded_date
            url
          }
        }
        id
        english_name
        arabic_name
        added_date
        status
        city
        phone
        location {
          lat
          lng
        }
        hours {
          enabled
          label
          first_open
          first_close
          second_open
          second_close
          second_shift
          timings
        }
      }
      plate
      model
      current_km
      daily_km
      extra_km
      status
      city
      driver
      driverPrice
      delivery
      deliveryPrice
      daily
      weekly
      monthly
      category
      fuel
      gearbox
      ac
      seats
      navigation
      insurance
    }
    customer_info {
      id
      company_info {
        id
        email
        call_center
        english_name
        arabic_name
        added_date
        documents {
          label
          description
          uploaded_by
          uploaded_for
          uploaded_date
          url
        }
      }
      email
      phone
      birth_date
      id_expiration
      license_expiration
      nationality
      occupation
      added_date
      status
      documents {
        label
        description
        uploaded_by
        uploaded_for
        uploaded_date
        url
      }
    }
    from
    to
    driver
    delivery
    location {
      lat
      lng
    }
    discount
    vat
    totalPrice
    status
  }
}
`;
