import { IonPage, IonReactRouter, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { Assignment, CalendarToday, DirectionsCar, HomeRounded, Person, Settings, Store } from '@material-ui/icons';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';
import { PrivateRoute } from './components/PrivateRoute';
import LoginPage from './pages/auth/Login';
import RegisterPage from './pages/auth/Register';
import BranchesPage from './pages/branches/BranchesPage';
import CarsPage from './pages/cars/CarsPage';
import CustomersPage from './pages/customers/CustomersPage';
import HomePage from './pages/Home';
import ReportsPage from './pages/Reports';
import ReservationsPage from './pages/reservations/ReservationsPage';
import SettingsPage from './pages/Settings';
import { AppPage } from './services/declarations';

const appPages: AppPage[] = [
	{
		title: 'Dashboard',
		url: '/home',
		icon: <HomeRounded />,
	},
	{
		title: 'Reservations',
		url: '/home/reservations',
		icon: <CalendarToday />,
	},
	{
		title: 'Branches',
		url: '/home/branches',
		icon: <Store />,
	},
	{
		title: 'Cars',
		url: '/home/cars',
		icon: <DirectionsCar />,
	},
	{
		title: 'Customers',
		url: '/home/customers',
		icon: <Person />,
	},
	{
		title: 'Reports',
		url: '/home/reports',
		icon: <Assignment />,
	},
	{
		title: 'Settings',
		url: '/home/settings',
		icon: <Settings />,
	},
];

const AppRouter: React.FunctionComponent = () => {
	return (
		<IonReactRouter>
			<IonRouterOutlet>
				<Route path='/login' component={LoginPage} exact={true} />
				<Route path='/register' component={RegisterPage} exact={true} />
				<Route path='/home'>
					<IonSplitPane contentId='main'>
						<Menu appPages={appPages} />
						<IonPage id='main'>
							<PrivateRoute path='/home' component={HomePage} exact={true} />
							<PrivateRoute path='/home/reservations' component={ReservationsPage} exact={true} />
							<PrivateRoute path='/home/branches' component={BranchesPage} exact={true} />
							<PrivateRoute path='/home/cars' component={CarsPage} exact={true} />
							<PrivateRoute path='/home/customers' component={CustomersPage} exact={true} />
							<PrivateRoute path='/home/reports' component={ReportsPage} exact={true} />
							<PrivateRoute path='/home/settings' component={SettingsPage} exact={true} />
						</IonPage>
					</IonSplitPane>
				</Route>
				<Route exact path='/' render={() => <Redirect to='/home' />} />
			</IonRouterOutlet>
		</IonReactRouter>
	);
};

export default AppRouter;
