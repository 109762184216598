import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { Modal, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { Place } from '../../services/declarations';

const MapComponent = withScriptjs(
	withGoogleMap<any>((props) => (
		<GoogleMap zoom={props.zoom} center={{ lat: props.center.lat, lng: props.center.lng }}>
			{props.center && (
				<Marker
					draggable
					onDragEnd={(event) => {
						const location = { lat: event.latLng.lat(), lng: event.latLng.lng() };
						const geocoder = new window.google.maps.Geocoder();
						const { OK } = window.google.maps.GeocoderStatus;
						geocoder.geocode({ location }, (results, status) => {
							if (status === OK) {
								const place = results[0];
								props.onPlaceChange(place);
							}
						});
					}}
					position={{ lat: props.center.lat, lng: props.center.lng }}
				/>
			)}
		</GoogleMap>
	)),
);

interface Props {
	onHide(): void;
	show: boolean;
	setPlace(place: Place): void;
	place: Place | null;
}

export const LocationModal = (props: Props) => {
	const [ zoom, setZoom ] = useState(8);

	const [ place, setPlace ] = useState({
		city: 'Riyadh',
		location: {
			lat: 24.7135517,
			lng: 46.67529569999999,
		},
	});

	useEffect(
		() => {
			if (props.place) {
				setPlace(props.place);
			}
		},
		[ props.place ],
	);
	const handlePlace = (place) => {
		let city = '';
		let location: any = {};
		for (let i = 0; i < place.address_components.length; i++) {
			if (place.address_components[i].types[0] === 'locality') {
				city = place.address_components[i].long_name;
				location.lat = place.geometry.location.lat();
				location.lng = place.geometry.location.lng();
				setPlace({
					city,
					location,
				});
				setZoom(place.address_components.length * 3);
				break;
			}
		}
	};
	return (
		<Modal
			style={{ minWidth: '50%' }}
			onCancel={() => {
				props.onHide();
			}}
			visible={props.show}
			centered
			title="Select Location"
			footer={[
				<Button
					type="primary"
					onClick={() => {
						props.setPlace(place);
						props.onHide();
					}}
				>
					Save
				</Button>,
				<Button onClick={props.onHide}>Cancel</Button>,
			]}
		>
			<GooglePlacesAutocomplete
				inputStyle={{
					width: '100%',
				}}
				onSelect={(maps) => {
					geocodeByPlaceId(maps.place_id)
						.then((place) => {
							place = place[0];
							handlePlace(place);
						})
						.catch((err) => console.log(err));
				}}
			/>
			<MapComponent
				zoom={zoom}
				center={place.location}
				onPlaceChange={(place) => handlePlace(place)}
				googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBxaPEhurwZN5nr8lBSqt36J6ZjGl3Zvfs&libraries=geometry,drawing,places&language=en"
				loadingElement={<div style={{ height: `100%` }} />}
				containerElement={<div style={{ height: `400px` }} />}
				mapElement={<div style={{ height: `100%` }} />}
			/>
		</Modal>
	);
};
