export const models = {
	Acura: [ 'Acura MDX', 'Acura TLX' ],
	'Alfa-Romeo': [
		'Alfa Romeo 147',
		'Alfa Romeo 156',
		'Alfa Romeo 159',
		'Alfa Romeo 166',
		'Alfa Romeo 4C',
		'Alfa Romeo Brera',
		'Alfa Romeo Giulietta',
		'Alfa Romeo Giulietta QV',
		'Alfa Romeo GT Coupe',
		'Alfa Romeo Mito',
		'Alfa Romeo Spider',
	],
	'Aston-Martin': [
		'Aston Martin DB7',
		'Aston Martin DB9',
		'Aston Martin DB9 Volante',
		'Aston Martin DBS',
		'Aston Martin One-77',
		'Aston Martin Rapide',
		'Aston Martin V12 Vantage',
		'Aston Martin V12 Vantage Roadster',
		'Aston Martin V8 Vantage',
		'Aston Martin V8 Vantage Roadster',
		'Aston Martin Vanquish',
		'Aston Martin Zagato',
	],
	Audi: [
		'Audi A1',
		'Audi A3',
		'Audi A3 Sedan',
		'Audi A4',
		'Audi A5 Cabriolet',
		'Audi A5 Coupe',
		'Audi A5 Sportback',
		'Audi A6',
		'Audi A7',
		'Audi A8',
		'Audi Q3',
		'Audi Q5',
		'Audi Q7',
		'Audi R8',
		'Audi R8 Spyder',
		'Audi RS 3',
		'Audi RS 4',
		'Audi RS 5',
		'Audi RS 6',
		'Audi RS 7',
		'Audi RS Q3',
		'Audi S Q5',
		'Audi S3',
		'Audi S5',
		'Audi S6',
		'Audi S7',
		'Audi S8',
		'Audi TT',
	],
	BAIC: [ 'BAIC A1 Hatchback', 'BAIC A1 Sedan', 'BAIC A5 Sedan' ],
	Bentley: [
		'Bentley Arnage',
		'Bentley Azure',
		'Bentley Brooklands',
		'Bentley Continental',
		'Bentley Continental Flying Spur',
		'Bentley Continental GT',
		'Bentley Continental GTC',
		'Bentley Flying Spur',
		'Bentley Mulsanne',
	],
	BMW: [
		'BMW 1-Series',
		'BMW 2-Series',
		'BMW 3-Series',
		'BMW 3-Series Convertible',
		'BMW 3-Series Coupe',
		'BMW 4-Series',
		'BMW 4-Series Convertible',
		'BMW 4-Series Gran Coupe',
		'BMW 5-Series',
		'BMW 5-Series GT',
		'BMW 6-Series Cabriolet',
		'BMW 6-Series Coupe',
		'BMW 6-Series Gran Coupe',
		'BMW 7-Series',
		'BMW 8-Series',
		'BMW i8',
		'BMW M2',
		'BMW M3',
		'BMW M3 Convertible',
		'BMW M3 Coupe',
		'BMW M4',
		'BMW M4 Convertible',
		'BMW M5',
		'BMW M6 Cabriolet',
		'BMW M6 Coupe',
		'BMW M6 Gran Coupe',
		'BMW X1',
		'BMW X3',
		'BMW X4',
		'BMW X5',
		'BMW X5 M',
		'BMW X6',
		'BMW X6 M',
		'BMW Z4',
	],
	Brilliance: [ 'Brilliance FRV', 'Brilliance FRV Cross', 'Brilliance FSV', 'Brilliance H530', 'Brilliance V5' ],
	Bugatti: [ 'Bugatti Veyron' ],
	BYD: [ 'BYD F3', 'BYD F5', 'BYD F6', 'BYD F7', 'BYD S6' ],
	Cadillac: [
		'Cadillac ATS',
		'Cadillac ATS Coupe',
		'Cadillac ATS-V',
		'Cadillac ATS-V Coupe',
		'Cadillac BLS',
		'Cadillac CT6',
		'Cadillac CTS',
		'Cadillac CTS Coupe',
		'Cadillac CTS-V',
		'Cadillac DTS',
		'Cadillac Escalade',
		'Cadillac SLS',
		'Cadillac SRX',
		'Cadillac STS',
		'Cadillac XLR',
		'Cadillac XTS',
	],
	Changan: [ 'Changan CS35', 'Changan CS75', 'Changan Eado' ],
	Chery: [
		'Chery A11 Windcloud',
		'Chery Arrizo 7',
		'Chery B11 Oriental Son',
		'Chery E5',
		'Chery E8',
		'Chery S11 QQ',
		'Chery T11 Tiggo',
		'Chery Tiggo 5',
		'Chery V5 Crossover',
	],
	Chevrolet: [
		'Chevrolet Avalanche',
		'Chevrolet Aveo',
		'Chevrolet Aveo5',
		'Chevrolet Camaro',
		'Chevrolet Camaro Convertible',
		'Chevrolet Caprice',
		'Chevrolet Captiva',
		'Chevrolet Colorado',
		'Chevrolet Corvette',
		'Chevrolet Cruze',
		'Chevrolet CSV CR8',
		'Chevrolet Epica',
		'Chevrolet Impala',
		'Chevrolet Lumina',
		'Chevrolet Malibu',
		'Chevrolet Optra',
		'Chevrolet Silverado',
		'Chevrolet Sonic',
		'Chevrolet Spark',
		'Chevrolet Suburban',
		'Chevrolet T-Series',
		'Chevrolet Tahoe',
		'Chevrolet Trailblazer',
		'Chevrolet Traverse',
		'Chevrolet Trax',
		'Chevrolet Uplander',
	],
	Chrysler: [
		'Chrysler 200',
		'Chrysler 300 SRT',
		'Chrysler 300C',
		'Chrysler Crossfire',
		'Chrysler Grand Voyager',
		'Chrysler PT Cruiser',
		'Chrysler Sebring',
	],
	Citroen: [
		'Citroen C-Elysee',
		'Citroen C2',
		'Citroen C3',
		'Citroen C4',
		'Citroen C4 Picasso',
		'Citroen C5',
		'Citroen C8',
		'Citroen DS3',
		'Citroen DS4',
		'Citroen DS5',
		'Citroen Saxo',
		'Citroen Xsara Picasso',
	],
	CMC: [ 'CMC Z7' ],
	Daihatsu: [
		'Daihatsu Charade',
		'Daihatsu Gran Max Pickup',
		'Daihatsu Gran Max Van',
		'Daihatsu Materia',
		'Daihatsu Sirion',
		'Daihatsu Terios',
		'Daihatsu YRV',
	],
	Dodge: [
		'Dodge Avenger',
		'Dodge Caliber',
		'Dodge Challenger',
		'Dodge Challenger SRT',
		'Dodge Charger',
		'Dodge Charger SRT',
		'Dodge Dakota',
		'Dodge Dart',
		'Dodge Durango',
		'Dodge Nitro',
		'Dodge Ram',
		'Dodge Viper',
	],
	Dongfeng: [ 'Dongfeng H30 Cross', 'Dongfeng S30' ],
	Ferrari: [
		'Ferrari 360',
		'Ferrari 458',
		'Ferrari 458 Spider',
		'Ferrari 488 GTB',
		'Ferrari 488 Spider',
		'Ferrari 599 GTB Fiorano',
		'Ferrari 612 Scaglietti',
		'Ferrari California',
		'Ferrari California T',
		'Ferrari F12 Berlinetta',
		'Ferrari F430',
		'Ferrari F430 Spider',
		'Ferrari FF',
		'Ferrari LaFerrari',
	],
	Fiat: [
		'Fiat 500',
		'Fiat 500L',
		'Fiat 500X',
		'Fiat Bravo',
		'Fiat Ducato',
		'Fiat Fiorino',
		'Fiat Linea',
		'Fiat Punto',
	],
	Fisker: [ 'Fisker Karma' ],
	Ford: [
		'Ford Crown Victoria',
		'Ford Ecosport',
		'Ford Edge',
		'Ford Escape',
		'Ford Everest',
		'Ford Expedition',
		'Ford Explorer',
		'Ford F-150',
		'Ford Fiesta',
		'Ford Figo',
		'Ford Five Hundred',
		'Ford Flex',
		'Ford Focus',
		'Ford Focus ST',
		'Ford Freestar',
		'Ford Fusion',
		'Ford GT',
		'Ford Mondeo',
		'Ford Mustang',
		'Ford Mustang Convertible',
		'Ford Ranger',
		'Ford Shelby GT500',
		'Ford Taurus',
	],
	Foton: [ 'Foton SUP', 'Foton Tunland', 'Foton View C1 Minibus' ],
	GAC: [ 'GAC GA3', 'GAC GA5', 'GAC GS5' ],
	Geely: [
		'Geely CK',
		'Geely Emgrand 7',
		'Geely Emgrand 8',
		'Geely Emgrand GT',
		'Geely Emgrand X7',
		'Geely GC2',
		'Geely GC6',
		'Geely GC7',
		'Geely GX2',
	],
	GMC: [ 'GMC Acadia', 'GMC Envoy', 'GMC Sierra 1500', 'GMC Sierra Z71', 'GMC Terrain', 'GMC Yukon' ],
	'Great-Wall': [ 'Great Wall Florid', 'Great Wall Wingle' ],
	Haval: [ 'Haval H2', 'Haval H6', 'Haval H8', 'Haval H9' ],
	Honda: [
		'Honda Accord',
		'Honda Accord Coupe',
		'Honda Accord Crosstour',
		'Honda City',
		'Honda Civic',
		'Honda Civic Hatchback',
		'Honda CR-V',
		'Honda CRX',
		'Honda Jazz',
		'Honda Legend',
		'Honda MR-V',
		'Honda Odyssey',
		'Honda Odyssey J',
		'Honda Pilot',
		'Honda S2000',
	],
	Hummer: [ 'Hummer H2', 'Hummer H3' ],
	Hyundai: [
		'Hyundai Accent',
		'Hyundai Atos Prime',
		'Hyundai Azera',
		'Hyundai Centennial',
		'Hyundai Coupe',
		'Hyundai Creta',
		'Hyundai Elantra',
		'Hyundai Elantra Coupe',
		'Hyundai Genesis',
		'Hyundai Genesis Coupe',
		'Hyundai Getz',
		'Hyundai Grand i10',
		'Hyundai Grand Santa Fe',
		'Hyundai H1',
		'Hyundai i10',
		'Hyundai i20',
		'Hyundai i30',
		'Hyundai i40',
		'Hyundai Matrix',
		'Hyundai Santa Fe',
		'Hyundai Sonata',
		'Hyundai Terracan',
		'Hyundai Trajet',
		'Hyundai Tucson',
		'Hyundai Veloster',
		'Hyundai Veloster Turbo',
		'Hyundai Veracruz',
	],
	Infiniti: [
		'Infiniti EX',
		'Infiniti FX',
		'Infiniti G',
		'Infiniti G35',
		'Infiniti G35 Coupe',
		'Infiniti G37 Convertible',
		'Infiniti G37 Coupe',
		'Infiniti JX',
		'Infiniti M',
		'Infiniti Q45',
		'Infiniti Q50',
		'Infiniti Q60 Convertible',
		'Infiniti Q60 Coupe',
		'Infiniti Q70',
		'Infiniti QX50',
		'Infiniti QX56',
		'Infiniti QX60',
		'Infiniti QX70',
		'Infiniti QX80',
	],
	International: [ 'International MXT' ],
	Isuzu: [ 'Isuzu D-Max', 'Isuzu MU-X', 'Isuzu Trooper' ],
	JAC: [ 'JAC J4', 'JAC J5', 'JAC J6', 'JAC M5 MPV' ],
	Jaguar: [
		'Jaguar F-Pace',
		'Jaguar F-Type',
		'Jaguar F-Type Coupe',
		'Jaguar F-Type SVR Convertible',
		'Jaguar F-Type SVR Coupe',
		'Jaguar S-Type',
		'Jaguar X-Type',
		'Jaguar XE',
		'Jaguar XF',
		'Jaguar XFR-S',
		'Jaguar XJ',
		'Jaguar XK',
		'Jaguar XK Convertible',
	],
	Jeep: [
		'Jeep Cherokee',
		'Jeep Commander',
		'Jeep Compass',
		'Jeep Grand Cherokee',
		'Jeep Patriot',
		'Jeep Renegade',
		'Jeep Wrangler',
		'Jeep Wrangler Unlimited',
	],
	Kia: [
		'Kia Cadenza',
		'Kia Carens',
		'Kia Carnival',
		'Kia Cerato',
		'Kia Cerato Koup',
		'Kia Mohave',
		'Kia Opirus',
		'Kia Optima',
		'Kia Picanto',
		'Kia Quoris',
		'Kia Rio',
		'Kia Rio Hatchback',
		'Kia Sorento',
		'Kia Soul',
		'Kia Sportage',
	],
	'King-Long': [ 'King Long Mini Van' ],
	Koenigsegg: [ 'Koenigsegg CCX' ],
	KTM: [ 'KTM X-Bow' ],
	Lamborghini: [
		'Lamborghini Aventador',
		'Lamborghini Aventador Roadster',
		'Lamborghini Gallardo',
		'Lamborghini Huracan',
		'Lamborghini Huracan Spyder',
		'Lamborghini Murcielago',
	],
	'Land-Rover': [
		'Land Rover Defender',
		'Land Rover Discovery Sport',
		'Land Rover Freelander',
		'Land Rover LR2',
		'Land Rover LR3',
		'Land Rover LR4',
		'Land Rover Range Rover',
		'Land Rover Range Rover Evoque',
		'Land Rover Range Rover Evoque Convertible',
		'Land Rover Range Rover Evoque Coupe',
		'Land Rover Range Rover Sport',
		'Land Rover Range Rover Sport SVR',
	],
	Lexus: [
		'Lexus CT',
		'Lexus ES',
		'Lexus GS',
		'Lexus GX',
		'Lexus IS',
		'Lexus IS C',
		'Lexus IS F',
		'Lexus LFA',
		'Lexus LS',
		'Lexus LX',
		'Lexus NX',
		'Lexus RC',
		'Lexus RC F',
		'Lexus RX',
		'Lexus SC',
	],
	Lincoln: [
		'Lincoln MKC',
		'Lincoln MKS',
		'Lincoln MKT',
		'Lincoln MKX',
		'Lincoln MKZ',
		'Lincoln Navigator',
		'Lincoln Town Car',
	],
	Lotus: [ 'Lotus Elise', 'Lotus Evora', 'Lotus Exige' ],
	Luxgen: [ 'Luxgen Luxgen7 MPV', 'Luxgen Luxgen7 SUV', 'Luxgen S5', 'Luxgen U6', 'Luxgen U7' ],
	Mahindra: [ 'Mahindra Scorpio' ],
	Maserati: [
		'Maserati Coupe',
		'Maserati Ghibli',
		'Maserati GranCabrio',
		'Maserati GranTurismo',
		'Maserati Levante',
		'Maserati Quattroporte',
		'Maserati Spyder',
	],
	Maybach: [ 'Maybach 57', 'Maybach 62' ],
	Mazda: [
		'Mazda 2',
		'Mazda 2 Sedan',
		'Mazda 3',
		'Mazda 6',
		'Mazda 6 Ultra',
		'Mazda B-Series',
		'Mazda CX-5',
		'Mazda CX-7',
		'Mazda CX-9',
		'Mazda MX-5',
	],
	McLaren: [
		'McLaren 540C Coupe',
		'McLaren 570S Coupe',
		'McLaren 650S',
		'McLaren 650S Spider',
		'McLaren 675LT',
		'McLaren 675LT Spider',
		'McLaren MP4-12C',
		'McLaren MP4-12C Spider',
		'McLaren P1',
	],
	'Mercedes-Benz': [
		'Mercedes-Benz A 45 AMG',
		'Mercedes-Benz A-Class',
		'Mercedes-Benz AMG GT',
		'Mercedes-Benz B-Class',
		'Mercedes-Benz C 63 AMG',
		'Mercedes-Benz C 63 AMG Coupe',
		'Mercedes-Benz C-Class',
		'Mercedes-Benz C-Class Coupe',
		'Mercedes-Benz CL-Class',
		'Mercedes-Benz CLA 45 AMG',
		'Mercedes-Benz CLA-Class',
		'Mercedes-Benz CLC-Class',
		'Mercedes-Benz CLK-Class',
		'Mercedes-Benz CLS 63 AMG',
		'Mercedes-Benz CLS Shooting Brake',
		'Mercedes-Benz CLS-Class',
		'Mercedes-Benz E 55 AMG',
		'Mercedes-Benz E 63 AMG',
		'Mercedes-Benz E-Class',
		'Mercedes-Benz E-Class Cabriolet',
		'Mercedes-Benz E-Class Coupe',
		'Mercedes-Benz G 63 AMG',
		'Mercedes-Benz G 65 AMG',
		'Mercedes-Benz G-Class',
		'Mercedes-Benz GL-Class',
		'Mercedes-Benz GLA-Class',
		'Mercedes-Benz GLC-Class',
		'Mercedes-Benz GLE 63 AMG',
		'Mercedes-Benz GLE Coupe 63 AMG',
		'Mercedes-Benz GLE-Class',
		'Mercedes-Benz GLE-Class Coupe',
		'Mercedes-Benz GLK-Class',
		'Mercedes-Benz GLS 63 AMG',
		'Mercedes-Benz GLS-Class',
		'Mercedes-Benz M-Class',
		'Mercedes-Benz ML 63 AMG',
		'Mercedes-Benz R-Class',
		'Mercedes-Benz S 63 AMG',
		'Mercedes-Benz S 63 AMG Coupe',
		'Mercedes-Benz S 65 AMG',
		'Mercedes-Benz S 65 AMG Coupe',
		'Mercedes-Benz S-Class',
		'Mercedes-Benz S-Class Coupe',
		'Mercedes-Benz S-Class Maybach',
		'Mercedes-Benz SL 63 AMG',
		'Mercedes-Benz SL 65 AMG',
		'Mercedes-Benz SL-Class',
		'Mercedes-Benz SLK-Class',
		'Mercedes-Benz SLR McLaren',
		'Mercedes-Benz SLS AMG',
		'Mercedes-Benz SLS AMG Roadster',
		'Mercedes-Benz Sprinter',
		'Mercedes-Benz Viano',
		'Mercedes-Benz Vito',
	],
	Mercury: [ 'Mercury Grand Marquis', 'Mercury Mariner', 'Mercury Milan', 'Mercury Montego', 'Mercury Mountaineer' ],
	MG: [ 'MG 3', 'MG 350', 'MG 5', 'MG 550', 'MG 6', 'MG 750', 'MG GS' ],
	Mini: [ 'Mini Clubman', 'Mini Cooper', 'Mini Countryman', 'Mini Coupe', 'Mini Paceman', 'Mini Roadster' ],
	Mitsubishi: [
		'Mitsubishi ASX',
		'Mitsubishi Attrage',
		'Mitsubishi Eclipse',
		'Mitsubishi Galant',
		'Mitsubishi Grandis',
		'Mitsubishi L200',
		'Mitsubishi Lancer',
		'Mitsubishi Lancer Evolution',
		'Mitsubishi Lancer EX',
		'Mitsubishi Lancer Fortis',
		'Mitsubishi Magna',
		'Mitsubishi Mirage',
		'Mitsubishi Montero Sport',
		'Mitsubishi Nativa',
		'Mitsubishi Outlander',
		'Mitsubishi Pajero',
		'Mitsubishi Pajero Sport',
		'Mitsubishi Pajero SWB',
	],
	Nissan: [
		'Nissan 350Z',
		'Nissan 370Z',
		'Nissan 370Z Roadster',
		'Nissan Altima',
		'Nissan Altima Coupe',
		'Nissan Armada',
		'Nissan GT-R',
		'Nissan Juke',
		'Nissan Maxima',
		'Nissan Micra',
		'Nissan Murano',
		'Nissan Navara',
		'Nissan Pathfinder',
		'Nissan Pathfinder Classic',
		'Nissan Patrol',
		'Nissan Patrol Safari',
		'Nissan Pickup',
		'Nissan Qashqai',
		'Nissan Sentra',
		'Nissan Sunny',
		'Nissan Tiida',
		'Nissan Urvan',
		'Nissan X-Trail',
		'Nissan Xterra',
	],
	Noble: [ 'Noble M600' ],
	Opel: [
		'Opel Adam',
		'Opel Antara',
		'Opel Astra',
		'Opel Astra OPC',
		'Opel Corsa',
		'Opel Corsa OPC',
		'Opel Insignia',
		'Opel Insignia OPC',
		'Opel Meriva',
		'Opel Mokka',
		'Opel Vectra',
	],
	Oullim: [ 'Oullim Spirra' ],
	Pagani: [ 'Pagani Huayra' ],
	Peugeot: [
		'Peugeot 2008',
		'Peugeot 206',
		'Peugeot 207',
		'Peugeot 208',
		'Peugeot 3008',
		'Peugeot 301',
		'Peugeot 307',
		'Peugeot 308',
		'Peugeot 308 CC',
		'Peugeot 407',
		'Peugeot 407 Coupe',
		'Peugeot 408',
		'Peugeot 5008',
		'Peugeot 508',
		'Peugeot 607',
		'Peugeot Boxer',
		'Peugeot Partner',
		'Peugeot RCZ',
	],
	PGO: [ 'PGO Cevennes', 'PGO Speedster' ],
	Porsche: [
		'Porsche 911',
		'Porsche 911 Cabriolet',
		'Porsche 918 Spyder',
		'Porsche Boxster',
		'Porsche Cayenne',
		'Porsche Cayman',
		'Porsche Macan',
		'Porsche Panamera',
	],
	Proton: [ 'Proton GEN-2', 'Proton Savvy', 'Proton Waja', 'Proton Wira' ],
	Ram: [ 'Ram 1500' ],
	Renault: [
		'Renault Captur',
		'Renault Clio',
		'Renault Clio RS',
		'Renault Dokker Van',
		'Renault Duster',
		'Renault Espace',
		'Renault Fluence',
		'Renault Koleos',
		'Renault Laguna',
		'Renault Laguna Coupe',
		'Renault Logan',
		'Renault Logan Van',
		'Renault Megane',
		'Renault Megane CC',
		'Renault Megane RS',
		'Renault Safrane',
		'Renault Sandero',
		'Renault Scenic',
		'Renault Symbol',
		'Renault Trafic',
		'Renault Twizy',
		'Renault Zoe',
	],
	'Rolls-Royce': [
		'Rolls Royce Drophead Coupe',
		'Rolls Royce Ghost',
		'Rolls Royce Phantom',
		'Rolls Royce Silver Seraph',
		'Rolls Royce Wraith',
	],
	Saab: [ 'Saab 9-3', 'Saab 9-5', 'Saab 9-7X' ],
	Seat: [ 'Seat Altea', 'Seat Cordoba', 'Seat Exeo', 'Seat Ibiza', 'Seat Leon', 'Seat Toledo' ],
	Skoda: [ 'Skoda Fabia', 'Skoda Octavia', 'Skoda Roomster', 'Skoda Superb', 'Skoda Yeti' ],
	Spyker: [ 'Spyker C8' ],
	Ssangyong: [
		'Ssangyong Actyon',
		'Ssangyong Korando',
		'Ssangyong Kyron',
		'Ssangyong Musso',
		'Ssangyong Rexton',
		'Ssangyong Rodius',
		'Ssangyong Tivoli',
	],
	Subaru: [
		'Subaru BRZ',
		'Subaru Forester',
		'Subaru Impreza',
		'Subaru Impreza WRX',
		'Subaru Impreza WRX STI',
		'Subaru Legacy',
		'Subaru Outback',
		'Subaru Tribeca',
		'Subaru XV',
	],
	Suzuki: [
		'Suzuki Alto',
		'Suzuki APV',
		'Suzuki Carry',
		'Suzuki Celerio',
		'Suzuki Ciaz',
		'Suzuki Ertiga',
		'Suzuki Grand Vitara',
		'Suzuki Ignis',
		'Suzuki Jimny',
		'Suzuki Kizashi',
		'Suzuki Liana',
		'Suzuki Swift',
		'Suzuki Swift Dzire',
		'Suzuki SX4',
		'Suzuki Vitara',
		'Suzuki XL7',
	],
	Tata: [ 'Tata Indica', 'Tata Indigo', 'Tata Xenon' ],
	Toyota: [
		'Toyota 86',
		'Toyota Aurion',
		'Toyota Avalon',
		'Toyota Avanza',
		'Toyota Camry',
		'Toyota Corolla',
		'Toyota FJ Cruiser',
		'Toyota Fortuner',
		'Toyota Hiace',
		'Toyota Hilux',
		'Toyota Innova',
		'Toyota Land Cruiser',
		'Toyota Land Cruiser 70',
		'Toyota Land Cruiser Pickup',
		'Toyota Land Cruiser Prado',
		'Toyota Land Cruiser Prado SWB',
		'Toyota Previa',
		'Toyota Prius',
		'Toyota RAV-4',
		'Toyota Sequoia',
		'Toyota xA',
		'Toyota Yaris',
		'Toyota Yaris Sedan',
		'Toyota Zelas',
	],
	Volkswagen: [
		'Volkswagen Amarok',
		'Volkswagen Beetle',
		'Volkswagen Eos',
		'Volkswagen Golf',
		'Volkswagen Golf GTI',
		'Volkswagen Golf Plus',
		'Volkswagen Golf R',
		'Volkswagen Golf R32',
		'Volkswagen Jetta',
		'Volkswagen Multivan',
		'Volkswagen Passat',
		'Volkswagen Passat CC',
		'Volkswagen Phaeton',
		'Volkswagen Polo',
		'Volkswagen Polo Sedan',
		'Volkswagen Scirocco',
		'Volkswagen Scirocco R',
		'Volkswagen Sharan',
		'Volkswagen Tiguan',
		'Volkswagen Touareg',
		'Volkswagen Touran',
	],
	Volvo: [
		'Volvo C30',
		'Volvo C70',
		'Volvo S40',
		'Volvo S60',
		'Volvo S60 Polestar',
		'Volvo S80',
		'Volvo V40',
		'Volvo V50',
		'Volvo V60',
		'Volvo V60 Polestar',
		'Volvo V70',
		'Volvo XC60',
		'Volvo XC70',
		'Volvo XC90',
	],
	ZNA: [ 'ZNA Oting', 'ZNA Rich', 'ZNA Yumsun' ],
};

export const makes = Object.keys(models);

export const getModels = (make) => {
	return make ? models[make] : [];
};
