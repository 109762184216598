import { IonButtons, IonContent, IonHeader, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react';
import { Alert, Button, Col, Icon, Result, Row, Steps } from 'antd';
import Upload from 'antd/lib/upload';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { addCompany } from '../graphql/mutations';
import { CompanyType, DocumentType } from '../services/declarations';
import UserContext from '../services/userContext';
import './Home.scss';

const HomePage: React.FunctionComponent = () => {
	const user = useContext(UserContext);
	const [ files, setFiles ] = useState([] as any[]);
	let step = 2;
	if (user['custom:status'] === 'pending') {
		step = 3;
	} else if (user['custom:status'] === 'verified') {
		step = 4;
	}
	return (
		<React.Fragment>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Dashboard</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div style={{ padding: '2rem' }}>
					<Steps>
						<Steps.Step status='finish' title='Sign Up' icon={<Icon type='user' />} />
						<Steps.Step
							status={step === 2 ? 'process' : 'finish'}
							title='Submit Documents'
							icon={<Icon type='solution' />}
						/>
						<Steps.Step
							status={step === 3 ? 'process' : step === 4 ? 'finish' : 'wait'}
							title='Review'
							icon={<Icon type='eye' />}
						/>
						<Steps.Step
							status={step === 4 ? 'finish' : 'wait'}
							title='Verified'
							icon={<Icon type='check' />}
						/>
					</Steps>
				</div>
				<Row type='flex' style={{ padding: '2rem' }} align='top'>
					<Col style={{ width: '100%' }}>
						{step === 2 && (
							<Formik
								initialValues={{
									id: user['custom:trade_id'],
									email: user.email,
									call_center: '',
									english_name: '',
									arabic_name: '',
									files: '',
								}}
								render={({ isSubmitting, touched, errors, values, setFieldValue }) => (
									<Form>
										<Alert
											style={{ marginBottom: '2rem' }}
											description='You need to enter your company info and send us your company documents to be verified'
											message='More Information Required'
											type='info'
										/>
										<div className='form-group'>
											<label>Arabic Name</label>
											<Field
												style={{ width: '300px' }}
												name='arabic_name'
												type='text'
												className={
													'form-control' +
													(errors.arabic_name && touched.arabic_name ? ' is-invalid' : '')
												}
											/>
											<ErrorMessage
												name='arabic_name'
												component='div'
												className='invalid-feedback'
											/>
										</div>
										<div className='form-group'>
											<label>English Name</label>
											<Field
												style={{ width: '300px' }}
												name='english_name'
												type='text'
												className={
													'form-control' +
													(errors.english_name && touched.english_name ? ' is-invalid' : '')
												}
											/>
											<ErrorMessage
												name='english_name'
												component='div'
												className='invalid-feedback'
											/>
										</div>
										<div className='form-group'>
											<label>Email</label>
											<Field
												style={{ width: '300px' }}
												name='email'
												type='text'
												className={
													'form-control' +
													(errors.email && touched.email ? ' is-invalid' : '')
												}
											/>
											<ErrorMessage name='email' component='div' className='invalid-feedback' />
										</div>
										<div className='form-group'>
											<label>Call Center (if available)</label>
											<Field
												name='call_center'
												render={() => (
													<ReactPhoneInput
														defaultCountry={'sa'}
														value={values.call_center}
														onChange={(phone) => {
															setFieldValue('call_center', phone);
														}}
													/>
												)}
											/>
											<div
												className='invalid-feedback'
												style={{
													display:
														errors.call_center && touched.call_center ? 'block' : 'none',
												}}>
												Error in phone number
											</div>
										</div>
										<div className='form-group'>
											<label style={{ display: 'block', marginBottom: '0.5rem' }}>
												Please upload trade certificate and all other necessary files
											</label>
											<Upload
												name='file'
												multiple={true}
												onRemove={(file) => {
													const tempList = files.filter(
														(single) => single.name !== file.name,
													);
													setFiles(tempList);
												}}
												beforeUpload={(file) => {
													setFiles([ ...files, file ]);
													return false;
												}}>
												<Button>
													<Icon type='upload' /> Click to Select Files
												</Button>
											</Upload>

											<div
												className='invalid-feedback'
												style={{
													display: errors.files && touched.files ? 'block' : 'none',
												}}>
												You must upload at least one file
											</div>
										</div>

										<Button
											disabled={isSubmitting}
											type='primary'
											htmlType='submit'
											className='mr-2'>
											{isSubmitting ? 'Loading...' : 'Submit'}
										</Button>
									</Form>
								)}
								onSubmit={async (values, actions) => {
									console.log(values);
									actions.setErrors({});
									console.log(files);
									if (values.arabic_name.length < 2) {
										actions.setFieldError('arabic_name', 'Arabic Name is required');
										actions.setSubmitting(false);
										return;
									} else if (values.english_name.length < 2) {
										actions.setFieldError('english_name', 'English Name is required');
										actions.setSubmitting(false);
										return;
									} else if (values.email.length < 2) {
										actions.setFieldError('email', 'Email is required');
										actions.setSubmitting(false);
										return;
									} else if (files.length === 0) {
										actions.setFieldError('files', 'Error');
										actions.setSubmitting(false);
										return;
									}
									const docs = [] as DocumentType[];
									const today = new Date();
									const day = String(today.getDate()).padStart(2, '0');
									const month = String(today.getMonth() + 1).padStart(2, '0');
									const year = today.getFullYear();
									const added_date = year + '-' + month + '-' + day;
									try {
										for (let i = 0; i < files.length; i++) {
											const key = `${user['custom:trade_id']}-${user.email}-${files[i].name}`;
											await Storage.put(key, files[i]);
											const document: DocumentType = {
												uploaded_by: user.email,
												uploaded_for: user['custom:trade_id'],
												label: key,
												url: key,
												uploaded_date: added_date,
											};
											docs.push(document);
										}
										const companyData: CompanyType = {
											...values,
											id: user['custom:trade_id'],
											call_center: values.call_center.length > 6 ? values.call_center : '-',
											documents: docs,
										};
										await API.graphql(graphqlOperation(addCompany, companyData));
										window.location.reload();
									} catch (error) {
										console.warn(error);
									}
									actions.setSubmitting(false);
								}}
							/>
						)}
						{step === 3 && (
							<Result
								status='info'
								icon={<Icon type='solution' style={{ color: '#be1e2d' }} />}
								title='Under Review'
								subTitle='Your documents are under review. You can setup other parts of your dashboard while waiting i.e. Branches, Cars, ...ect'
							/>
						)}
					</Col>
				</Row>
			</IonContent>
		</React.Fragment>
	);
};

export default HomePage;
